import array from "juice-base/lib/array.js";

const allStandards = {
    juiceStandard: {
        value: "juice-standard",
        label: "Juice Standard",
    },
    commonCore: {
        value: "common-core",
        label: "Common Core",
    },
    stateStandard: {
        value: "state-standard",
        label: "State Standard",
    },
};

const ANCHOR_STANDARDS_GROUP = {
    keyIdeasAndDetails: "Key Ideas and Details",
    craftAndStructure: "Craft and Structure",
    integrationOfKnowledgeAndIdeas: "Integration of Knowledge and Ideas",
};

const getStandardsTypes = () => {
    return [
        allStandards.juiceStandard,
        allStandards.commonCore,
    ];
};

const getAllStandardsTypes = () => {
    return [
        allStandards.juiceStandard,
        allStandards.commonCore,
        allStandards.stateStandard,
    ];
};

const getAnchorStandardsGroups = () => {
    return [
        ANCHOR_STANDARDS_GROUP.keyIdeasAndDetails,
        ANCHOR_STANDARDS_GROUP.craftAndStructure,
        ANCHOR_STANDARDS_GROUP.integrationOfKnowledgeAndIdeas,
    ];
};

const getJuiceStandardValue = () => {
    return allStandards.juiceStandard.value;
};

const getCommonCoreValue = () => {
    return allStandards.commonCore.value;
};

const getStateStandardValue = () => {
    return allStandards.stateStandard.value;
};

const getDefaultStandardValue = (hasStateStandard) => {
    if (hasStateStandard) {
        return allStandards.stateStandard.value;
    }

    return allStandards.commonCore.value;
};

/* --- */

const getStandardByType = (standards, type) => {
    return array.findByFieldName(standards, "type", type);
};

const getStandardTypeByStandard = (std, stdTypeName) => {
    const stdTypes = std.standardTypes || [];

    for (let i = 0; i < stdTypes.length; i += 1) {
        if (stdTypes[i].standard === stdTypeName) {
            return stdTypes[i];
        }
    }

    return false;
};

const getStandardTypeByType = (std, type) => {
    const stdTypes = std.standardTypes || [];

    for (let i = 0; i < stdTypes.length; i += 1) {
        if (stdTypes[i].type === type) {
            return stdTypes[i];
        }
    }

    return false;
};

/* --- */

const getStandardsByGroupName = (standards, group) => {
    if (!Array.isArray(standards)) {
        return [];
    }

    for (let i = 0; i < standards.length; i += 1) {
        if (standards[i].anchorStandardGroup === group) {
            return standards[i].standards;
        }
    }

    return [];
};

const getStandardsGroups = (standards) => {
    if (!Array.isArray(standards)) {
        return [];
    }

    return standards.map((standard) => standard.anchorStandardGroup);
};

/* --- */

const getStandardById = (standards, standardId) => {
    return array.findOneById(standards, standardId);
};

const getStandardsByType = (standards, type) => {
    return standards.filter((s) => s.type === type);
};

const getJuiceStandard = (standards) => {
    return getStandardsByType(standards, allStandards.juiceStandard.value);
};

const getAllStandardsByType = (standards, type) => {
    const ret = [];

    if (standards) {
        for (let i = 0; i < standards.length; i += 1) {
            const stdTypes = standards[i].standardTypes || [];

            for (let j = 0; j < stdTypes.length; j += 1) {
                if (stdTypes[j].type === type) {
                    ret.push({
                        id: standards[i].id,
                        ...stdTypes[j],
                    });
                }
            }
        }
    }

    return ret;
};

/* --- */

const findStandardTypeByType = (standards, standardId, standardType) => {
    const std = getStandardById(standards, standardId);

    if (std) {
        return getStandardTypeByType(std, standardType);
    }

    return null;
};

const findStandardTypeByStandard = (standards, standardId, standardName) => {
    const std = getStandardById(standards, standardId);

    if (std) {
        return getStandardTypeByStandard(std, standardName);
    }

    return null;
};

const findStandardType = (standards, stdTypeName) => {
    if (standards && standards.length > 0) {
        for (let i = 0; i < standards.length; i += 1) {
            const std = standards[i];

            const stdType = getStandardTypeByStandard(std, stdTypeName);

            if (stdType) {
                return stdType;
            }
        }
    }

    return null;
};

/* --- */

const hasStandardByType = (standards, stdTypeName) => {
    for (let i = 0; i < standards.length; i += 1) {
        if (standards[i].type === stdTypeName) {
            if (standards[i].standard) {
                return true;
            }
            break;
        }
    }

    return false;
};

const hasStateStandard = (standards) => {
    return hasStandardByType(standards, allStandards.stateStandard.value);
};

/* --- */

const isSameStandardBySubStandard = (subStandard1, subStandard2) => {
    // NOTE: subStandard examples: RI 6.1, RI 8.1, RI 10.1, RI 12.1

    if (!subStandard1 || !subStandard2) {
        return false;
    }

    let s1 = subStandard1.split(" ");
    let s2 = subStandard2.split(" ");

    if (s1.length !== 2 || s2.length !== 2) {
        return false;
    }

    if (s1[0] !== s2[0]) {
        return false;
    }

    s1 = s1[1].split(".");
    s2 = s2[1].split(".");

    if (s1.length !== 2 || s2.length !== 2) {
        return false;
    }

    if (s1[1] === s2[1]) {
        return true;
    }

    return false;
};

const isKeyIdeasAndDetailsGroup = (type) => {
    return type === ANCHOR_STANDARDS_GROUP.keyIdeasAndDetails;
};

const isCraftAndStructureGroup = (type) => {
    return type === ANCHOR_STANDARDS_GROUP.craftAndStructure;
};

const isIntegrationOfKnowledgeAndIdeasGroup = (type) => {
    return type === ANCHOR_STANDARDS_GROUP.integrationOfKnowledgeAndIdeas;
};

/* --- */

export default {
    getStandardsTypes,
    getAllStandardsTypes,

    getAnchorStandardsGroups,

    getJuiceStandardValue,
    getCommonCoreValue,
    getStateStandardValue,
    getDefaultStandardValue,

    getStandardByType,
    getStandardTypeByStandard,
    getStandardTypeByType,
    getJuiceStandard,

    getStandardsByGroupName,
    getStandardsGroups,

    getStandardById,
    getStandardsByType,
    getAllStandardsByType,

    findStandardTypeByType,
    findStandardTypeByStandard,
    findStandardType,

    hasStandardByType,
    hasStateStandard,

    isSameStandardBySubStandard,

    isKeyIdeasAndDetailsGroup,
    isCraftAndStructureGroup,
    isIntegrationOfKnowledgeAndIdeasGroup,
};
