// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".XZ7qlNaejBFN2Xni1GcC {\n    z-index: var(--z-index-header);\n    position: absolute;\n    display: none;\n\n    top: 50%;\n    left: 50%;\n}\n\n.mQhnEUigYpfs_oyerDKM {\n    position: absolute;\n}\n\n.NIiVJM5zHiyfSw5jjI9M {\n    top: 2rem;\n}\n\n.BbdODumVegQTkYnCjXQB {\n    margin-bottom: 8rem;\n}\n\n@media only screen and (min-width: 901px) {\n    .XZ7qlNaejBFN2Xni1GcC {\n        display: block;\n    }\n}", "",{"version":3,"sources":["webpack://./base/components/tooltip-position/styles.module.css"],"names":[],"mappings":"AAAA;IACI,8BAA8B;IAC9B,kBAAkB;IAClB,aAAa;;IAEb,QAAQ;IACR,SAAS;AACb;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,SAAS;AACb;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI;QACI,cAAc;IAClB;AACJ","sourcesContent":[".tooltipPosition {\n    z-index: var(--z-index-header);\n    position: absolute;\n    display: none;\n\n    top: 50%;\n    left: 50%;\n}\n\n.position {\n    position: absolute;\n}\n\n.positionBottom {\n    top: 2rem;\n}\n\n.positionTop {\n    margin-bottom: 8rem;\n}\n\n@media only screen and (min-width: 901px) {\n    .tooltipPosition {\n        display: block;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tooltipPosition": "XZ7qlNaejBFN2Xni1GcC",
	"position": "mQhnEUigYpfs_oyerDKM",
	"positionBottom": "NIiVJM5zHiyfSw5jjI9M",
	"positionTop": "BbdODumVegQTkYnCjXQB"
};
export default ___CSS_LOADER_EXPORT___;
