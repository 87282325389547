import array from "juice-base/lib/array.js";
import text from "juice-base/text/index.js";
import Urls from "juice-base/project/urls.js";


const loadAllClasses = (services, params) => {
    const { actions, api } = services;

    return async (dispatch) => {
        dispatch(actions.teacher.setClassesLoading());

        const res = await api.classes.getTeacherClasses({
            session: params.session,
        });

        const classes = res.ok
            ? res.classes || []
            : [];

        dispatch(actions.teacher.setClasses({
            classes,
        }));

        dispatch(actions.teacher.setMaxClasses({
            maxClasses: res.maxClasses || null,
        }));

        if (params.onFinish) {
            params.onFinish(classes);
        }
    };
};

const loadClasses = (services, params) => {
    const { api, storage, actions } = services;

    return async (dispatch) => {
        const res = await api.classes.getTeacherClasses({
            session: params.session,
        });

        if (!res.ok) {
            return;
        }

        const classes = res.classes || [];
        let classId = storage.local.loadTeacherSelectedClass();
        const selectedClass = array.findOneById(classes, classId);

        if (!selectedClass && classes[0]) {
            classId = classes[0].id || -1;
            storage.local.saveTeacherSelectedClass(classId);
        }

        dispatch(actions.teacher.setMaxClasses({
            maxClasses: res.maxClasses || null,
        }));

        dispatch(actions.teacher.setTeacherSelectedClass({
            selectedClassId: classId,
        }));

        dispatch(actions.teacher.setClasses({
            classes,
        }));

        if (params.onFinish && classes.length > 0) {
            params.onFinish(classes);
        }
    };
};

const reloadClasses = (services) => {
    const { api, actions } = services;

    return async (dispatch, getState) => {
        const state = getState();

        if (!state.user.session) {
            return;
        }

        const res = await api.classes.getTeacherClasses({
            session: state.user.session,
        });

        if (!res.ok) {
            return;
        }

        const classes = res.classes || [];

        dispatch(actions.teacher.setMaxClasses({
            maxClasses: res.maxClasses || null,
        }));

        dispatch(actions.teacher.setClasses({
            classes,
        }));
    };
};

// TODO: check old action
const loadClassScores = (services, params) => {
    const { api, actions } = services;

    return async (dispatch) => {
        const { classId, dateFrom, dateTo } = params;

        const range = `${dateFrom}-${dateTo}`;

        dispatch(actions.teacher.setTeacherClassScoresByRangeLoading({
            classId,
            range,
        }));

        const res = await api.classes.getClassStudentsScoresByRange({
            session: params.session,
            classId,
            dateFrom,
            dateTo,
        });

        let data = null;
        let error = "";

        if (res.ok) {
            data = res.data || null;
        } else {
            error = res.error;
        }

        dispatch(actions.teacher.setTeacherClassScoresByRange({
            classId,
            range,
            data,
            error,
        }));
    };
};

const loadClassNotifications = (services, params) => {
    const { actions, api } = services;

    return async (dispatch) => {
        const res = await api.classes.getClassNotifications({
            session: params.session,
            classId: params.classId,
        });

        const ns = res?.ok ? res.students || [] : [];

        dispatch(actions.notifications.setStudentsNotifications({
            classId: params.classId,
            notifications: ns,
        }));
    };
};

const loadAllNotifications = (services, params) => {
    return (dispatch) => {
        const loadClassesAction = loadClasses(services, params);
        const loadClassNotificationsAction = loadClassNotifications(services, params);

        loadClassesAction(dispatch);
        loadClassNotificationsAction(dispatch);
    };
};

const loadClassesPerformance = (services) => {
    const { api, actions } = services;

    return async (dispatch, getState) => {
        const state = getState();

        const { dateFrom, dateTo, categories } = state.navigation.params;

        if (!dateFrom || !dateTo || !categories) {
            return;
        }

        const range = `${dateFrom}-${dateTo}`;
        const cats = categories.replaceAll("-", ",");

        const isLoaded = state.teacher.classesPerformanceByRange?.[range]?.[categories]?.data
            || false;

        if (isLoaded) {
            return;
        }

        dispatch(actions.teacher.setTeacherClassesPerformanceByRangeLoading({
            range,
            categories: cats,
        }));

        const res = await api.classes.getClassesPerformance({
            session: state.user.session,
            categories: cats,
            dateFrom,
            dateTo,
        });

        let data = null;
        let error = "";

        if (res.ok) {
            data = res.data || null;
        } else {
            error = res.error;
        }

        dispatch(actions.teacher.setTeacherClassesPerformanceByRange({
            range,
            categories: cats,
            data,
            error,
        }));
    };
};

const reloadClassesPerformance = (services) => {
    const { api, actions } = services;

    return async (dispatch, getState) => {
        const state = getState();

        const { dateFrom, dateTo, categories } = state.navigation.params;

        if (!dateFrom || !dateTo || !categories) {
            return;
        }

        const range = `${dateFrom}-${dateTo}`;
        const cats = categories.replaceAll("-", ",");

        const res = await api.classes.getClassesPerformance({
            session: state.user.session,
            categories: cats,
            dateFrom,
            dateTo,
        });

        let data = null;
        let error = "";

        if (res.ok) {
            data = res.data || null;
        } else {
            error = res.error;
        }

        dispatch(actions.teacher.updateTeacherClassesPerformanceByRange({
            range,
            categories: cats,
            data,
            error,
        }));

        const seconds = 5;

        globalThis.setTimeout(() => {
            dispatch(actions.teacher.clearUpdatesStatusTeacherClassesPerformanceByRange({
                range,
                categories: cats,
            }));
        }, seconds * 1000);
    };
};

const loadClassesStatsByLearningStandards = (services) => {
    const { api, actions } = services;

    return async (dispatch, getState) => {
        const state = getState();

        const { dateFrom, dateTo } = state.navigation.params;

        if (!dateFrom || !dateTo) {
            return;
        }

        const range = `${dateFrom}-${dateTo}`;

        if (state.teacher.classesStatsByLearningStandards?.[range]?.data) {
            return;
        }

        /* --- */

        dispatch(actions.teacher.setTeacherClassesStatsByLearningStandardsLoading({
            range,
        }));

        const res = await api.classes.getClassesStatsByLearningStandards({
            session: state.user.session,
            dateFrom,
            dateTo,
        });

        let data = null;
        let error = "";

        if (res.ok) {
            data = res.data || null;
        } else {
            error = res.error;
        }

        dispatch(actions.teacher.setTeacherClassesStatsByLearningStandards({
            range,
            data,
            error,
        }));
    };
};

const reloadClassesStatsByLearningStandards = (services) => {
    const { api, actions } = services;

    return async (dispatch, getState) => {
        const state = getState();

        const { dateFrom, dateTo } = state.navigation.params;

        if (!dateFrom || !dateTo) {
            return;
        }

        const range = `${dateFrom}-${dateTo}`;

        const res = await api.classes.getClassesStatsByLearningStandards({
            session: state.user.session,
            dateFrom,
            dateTo,
        });

        let data = null;
        let error = "";

        if (res.ok) {
            data = res.data || null;
        } else {
            error = res.error;
        }

        dispatch(actions.teacher.setTeacherClassesStatsByLearningStandards({
            range,
            data,
            error,
        }));
    };
};

const loadClassesCategories = (services) => {
    const { api, actions } = services;

    return async (dispatch, getState) => {
        const state = getState();

        const { dateFrom, dateTo } = state.navigation.params;

        if (!dateFrom || !dateTo) {
            return;
        }

        const range = `${dateFrom}-${dateTo}`;

        const isLoaded = state.teacher.classesStoriesCategoriesByRange?.[range]?.isLoaded || false;

        if (isLoaded) {
            return;
        }

        dispatch(actions.teacher.setTeacherClassesCategoriesLoading({
            range,
        }));

        const res = await api.classes.getStoryCategoriesByClassId({
            session: state.user.session,
            dateFrom,
            dateTo,
        });

        if (!res.ok) {
            return;
        }

        dispatch(actions.teacher.setTeacherClassesCategories({
            range,
            categories: res.categories,
        }));
    };
};

const reloadClassesCategories = (services) => {
    const { api, actions } = services;

    return async (dispatch, getState) => {
        const state = getState();

        const { dateFrom, dateTo } = state.navigation.params;

        if (!dateFrom || !dateTo) {
            return;
        }

        const range = `${dateFrom}-${dateTo}`;

        const res = await api.classes.getStoryCategoriesByClassId({
            session: state.user.session,
            dateFrom,
            dateTo,
        });

        if (!res.ok) {
            return;
        }

        dispatch(actions.teacher.setTeacherClassesCategories({
            range,
            categories: res.categories,
        }));
    };
};

const loadClassAnnouncement = (services, params) => {
    const { api, actions } = services;
    const { classId } = params;

    return async (dispatch) => {
        dispatch(actions.teacher.setTeacherAnnouncementsByIdLoading({
            classId,
        }));

        const res = await api.classes.getClassAnnouncement({
            classId,
        });

        let data = null;
        let error = "";

        if (res.ok) {
            data = res.data;
        } else {
            error = res.error;
        }

        dispatch(actions.teacher.setTeacherAnnouncementsById({
            classId,
            data,
            error,
        }));
    };
};

const loadClassSponsor = (services, params) => {
    const { api, actions } = services;
    const { classId } = params;

    return async (dispatch) => {
        dispatch(actions.teacher.setTeacherSponsorsByIdLoading({
            classId,
        }));

        const res = await api.classes.getClassSponsors({
            classId,
        });

        let data = null;
        let error = "";

        if (res.ok) {
            data = res.data;
        } else {
            error = res.error;
        }

        dispatch(actions.teacher.setTeacherSponsorsById({
            classId,
            data,
            error,
        }));
    };
};

const loadAllClassesSponsors = (services, params) => {
    const { api, actions } = services;
    const { session, classes } = params;

    return async (dispatch) => {
        dispatch(actions.teacher.setTeacherClassesSponsorsLoading({
            classes,
        }));

        const res = await api.classes.getAllSponsorsByTeacher({
            session,
        });

        let classesSponsors = [];

        if (res.ok) {
            classesSponsors = res.data.map((cl) => ({
                id: cl.ID,
                data: cl,
                error: "",
            }));
        } else {
            classesSponsors = classes.map((cl) => ({
                id: cl.id,
                data: null,
                error: res.error,
            }));
        }

        dispatch(actions.teacher.setTeacherClassesSponsors({
            classesSponsors,
        }));
    };
};

const loadAllClassesAnnouncements = (services, params) => {
    const { api, actions } = services;
    const { session, classes } = params;

    return async (dispatch) => {
        dispatch(actions.teacher.setTeacherClassesAnnouncementsLoading({
            classes,
        }));

        const res = await api.classes.getAllClassesAnnouncementsByTeacher({
            session,
        });

        let classesAnnouncements = [];

        if (res.ok) {
            classesAnnouncements = res.data.map((cl) => ({
                id: cl.ID,
                data: cl || null,
                error: "",
            }));
        } else {
            classesAnnouncements = classes.map((cl) => ({
                id: cl.id,
                data: null,
                error: res.error,
            }));
        }

        dispatch(actions.teacher.setTeacherClassesAnnouncements({
            classesAnnouncements,
        }));
    };
};

const loadClassesStudents = (services, params) => {
    const { api, actions } = services;
    const { session } = params;

    return async (dispatch) => {
        dispatch(actions.teacher.setTeacherClassesStudentsLoading());

        const res = await api.classes.getTeacherClassesStudents({
            session,
        });

        let classes = [];

        if (res.ok) {
            classes = res.data || [];
        }

        dispatch(actions.teacher.setTeacherClassesStudents({
            classes,
        }));
    };
};

// TODO: replace later with teacher-class-daily-juices.js
const loadClassDailyJuicesScores = (services, params) => {
    const { api, actions } = services;
    const { classId, forceUpdate } = params;

    return async (dispatch, getState) => {
        const state = getState();

        const { dateFrom, dateTo } = state.navigation.params;
        const range = `${dateFrom}-${dateTo}`;

        if (state.teacher.classesScoresByDailyJuices?.[classId]?.[range]?.data
            && !forceUpdate) {
            return;
        }

        dispatch(actions.teacher.setTeacherClassScoresByDailyJuicesLoading({
            range,
            classId,
        }));

        const res = await api.teacherClasses.getTeacherClassScoresByDailyJuices({
            session: state.user.session,
            classId,
            dateFrom,
            dateTo,
        });

        let data = null;
        let error = "";

        if (res.ok) {
            data = res.data || null;
        } else {
            error = res.error || text.error;
        }

        dispatch(actions.teacher.setTeacherClassScoresByDailyJuices({
            classId,
            range,
            data,
            error,
        }));
    };
};

// TODO: replace later with teacher-class-assignments.js
const loadClassAssignmentsScores = (services, params) => {
    const { api, actions } = services;
    const { classId, forceUpdate } = params;

    return async (dispatch, getState) => {
        const state = getState();

        const { dateFrom, dateTo } = state.navigation.params;
        const range = `${dateFrom}-${dateTo}`;

        if (state.teacher.classesScoresByAssignments?.[classId]?.[range]?.data
            && !forceUpdate) {
            return;
        }

        dispatch(actions.teacher.setTeacherClassScoresByAssignmentsLoading({
            range,
            classId,
        }));

        const res = await api.teacherClasses.getTeacherClassScoresByAssignments({
            session: state.user.session,
            classId,
            dateFrom,
            dateTo,
        });

        let data = null;
        let error = "";

        if (res.ok) {
            data = res.data || null;
        } else {
            error = res.error || text.error;
        }

        dispatch(actions.teacher.setTeacherClassScoresByAssignments({
            classId,
            range,
            data,
            error,
        }));
    };
};

// TODO: replace later with teacher-class-assignments.js
const reloadClassAssignmentsScores = (services) => {
    const { api, actions } = services;

    return async (dispatch, getState) => {
        const state = getState();

        const { pathname } = state.navigation.location;

        if (!Urls.isClass(pathname)) {
            return;
        }

        const { selectedClassId } = state.teacher;

        if (!selectedClassId || selectedClassId === "-1") {
            return;
        }

        const { dateFrom, dateTo } = state.navigation.params;
        const range = `${dateFrom}-${dateTo}`;

        const res = await api.teacherClasses.getTeacherClassScoresByAssignments({
            session: state.user.session,
            classId: selectedClassId,
            dateFrom,
            dateTo,
        });

        let data = null;
        let error = "";

        if (res.ok) {
            data = res.data || null;
        } else {
            error = res.error || text.error;
        }

        dispatch(actions.teacher.updateTeacherClassScoresByAssignments({
            classId: selectedClassId,
            range,
            data,
            error,
        }));
    };
};

const loadClassDetails = (services, params) => {
    const { api, actions } = services;
    const { session, classId } = params;

    return async (dispatch) => {
        if (!classId) {
            return;
        }

        dispatch(actions.teacher.setTeacherClassesDetailsByIdLoading({
            classId,
        }));

        const res = await api.classes.getClassDetails({
            session,
            classId,
        });

        let data = null;
        let error = "";

        if (res.ok) {
            data = res.data || null;
        } else {
            error = res.error || text.error;
        }

        dispatch(actions.teacher.setTeacherClassesDetailsById({
            classId,
            data,
            error,
        }));
    };
};

const reloadDashboard = (services) => {
    return async (dispatch, getState) => {
        const state = getState();

        const { pathname } = state.navigation.location;

        if (!Urls.isDashboard(pathname)) {
            return;
        }

        const categoriesAction = reloadClassesCategories(services);
        const performanceAction = reloadClassesPerformance(services);
        const statsAction = reloadClassesStatsByLearningStandards(services);

        categoriesAction(dispatch, getState);
        performanceAction(dispatch, getState);
        statsAction(dispatch, getState);
    };
};

const setClassEndDate = (services, params) => {
    const { api, actions } = services;
    const { classId, endDate } = params;

    return async (dispatch, getState) => {
        const state = getState();

        const res = await api.teacherClasses.updateTeacherClassEndDate({
            session: state.user.session,
            classId,
            endDate,
        });

        let error = "";

        if (res.ok) {
            dispatch(actions.teacher.setClassEndDate({
                classId,
                endDate,
            }));
        } else {
            error = res.error || text.error;
        }

        return {
            error,
        };
    };
};

export default {
    loadAllClasses,
    loadClasses,
    reloadClasses,

    loadClassScores,

    loadClassNotifications,
    loadAllNotifications,

    loadClassesStatsByLearningStandards,
    loadClassesPerformance,
    loadClassesCategories,
    loadClassAnnouncement,
    loadClassSponsor,
    loadAllClassesSponsors,
    loadAllClassesAnnouncements,
    loadClassesStudents,

    loadClassDailyJuicesScores,

    loadClassAssignmentsScores,
    reloadClassAssignmentsScores,

    loadClassDetails,

    reloadDashboard,

    setClassEndDate,
};
