import React from "react";

import IconWrapper from "juice-base/icons/wrapper/index.js";


const IconAchievementStoriesRead = (props) => {
    return (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <IconWrapper {...props}>
            {(iconProps) => (
                <svg
                    width="94"
                    height="94"
                    viewBox="0 0 94 94"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className={iconProps.className}
                >
                    {iconProps.title}
                    <path d="M78 0H16C7.16344 0 0 7.16344 0 16V78C0 86.8366 7.16344 94 16 94H78C86.8366 94 94 86.8366 94 78V16C94 7.16344 86.8366 0 78 0Z" fill="url(#paint0_radial_307_4)" />
                    <path d="M69.0318 27.6615C66.3147 26.8047 63.3282 26.4375 60.4641 26.4375C55.6907 26.4375 50.5501 27.4167 47.0006 30.1094C43.4511 27.4167 38.3105 26.4375 33.537 26.4375C28.7636 26.4375 23.623 27.4167 20.0735 30.1094V65.9714C20.0735 66.5833 20.6855 67.1953 21.2974 67.1953C21.5422 67.1953 21.6646 67.0729 21.9094 67.0729C25.2141 65.4818 29.9876 64.3802 33.537 64.3802C38.3105 64.3802 43.4511 65.3594 47.0006 68.0521C50.3053 65.9714 56.3027 64.3802 60.4641 64.3802C64.5032 64.3802 68.6647 65.1146 72.0917 66.9505C72.3365 67.0729 72.4589 67.0729 72.7037 67.0729C73.3157 67.0729 73.9277 66.4609 73.9277 65.849V30.1094C72.4589 29.0078 70.8678 28.2734 69.0318 27.6615ZM24.9693 60.7083V32.5573C27.662 31.7005 30.5995 31.3333 33.537 31.3333C36.8172 31.3333 41.199 32.337 44.5527 33.7568V61.9078C41.199 60.488 36.8172 59.4844 33.537 59.4844C30.5995 59.4844 27.662 59.8516 24.9693 60.7083ZM69.0318 60.7083C66.3391 59.8516 63.4016 59.4844 60.4641 59.4844C57.1839 59.4844 52.8021 60.488 49.4485 61.9078V33.7568C52.8021 32.3125 57.1839 31.3333 60.4641 31.3333C63.4016 31.3333 66.3391 31.7005 69.0318 32.5573V60.7083Z" fill="white" />
                    <g opacity="0.3">
                        <path d="M44.5521 33.7078C41.1984 32.288 36.8166 31.2844 33.5364 31.2844C30.5989 31.2844 27.6614 31.6516 24.9688 32.5083V60.6594C27.6614 59.8026 30.5989 59.4354 33.5364 59.4354C36.8166 59.4354 41.1984 60.4391 44.5521 61.8588V33.7078Z" fill="url(#paint1_linear_307_4)" />
                    </g>
                    <g opacity="0.3">
                        <path d="M49.448 33.7078C52.8017 32.288 57.1834 31.2844 60.4636 31.2844C63.4011 31.2844 66.3386 31.6516 69.0313 32.5083V60.6594C66.3386 59.8026 63.4011 59.4354 60.4636 59.4354C57.1834 59.4354 52.8017 60.4391 49.448 61.8588V33.7078Z" fill="url(#paint2_linear_307_4)" />
                    </g>
                    <defs>
                        <radialGradient id="paint0_radial_307_4" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="rotate(45) scale(132.936)">
                            <stop stopColor="#A855F7" />
                            <stop offset="1" stopColor="#6366F1" />
                        </radialGradient>
                        <linearGradient id="paint1_linear_307_4" x1="34.7604" y1="31.2844" x2="34.7604" y2="61.8588" gradientUnits="userSpaceOnUse">
                            <stop stopColor="white" />
                            <stop offset="1" stopColor="white" stopOpacity="0.3" />
                        </linearGradient>
                        <linearGradient id="paint2_linear_307_4" x1="59.2397" y1="31.2844" x2="59.2397" y2="61.8588" gradientUnits="userSpaceOnUse">
                            <stop stopColor="white" />
                            <stop offset="1" stopColor="white" stopOpacity="0.3" />
                        </linearGradient>
                    </defs>
                </svg>
            )}
        </IconWrapper>
    );
};

export default IconAchievementStoriesRead;
