import React from "react";

import classNames from "juice-base/lib/class-names.js";

import styles from "./styles.module.css";


const TooltipSmallWrapper = (props) => {
    const renderTooltip = () => {
        if (!props.tooltip) {
            return null;
        }

        return (
            <div className={styles.wrapperTooltip}>
                {props.tooltip}
            </div>
        );
    };

    /* --- */

    const wrapperClassName = classNames({
        [styles.wrapper]: true,
        [props.className]: props.className,
    });

    return (
        <div className={wrapperClassName}>
            {props.children}
            {renderTooltip()}
        </div>
    );
};

TooltipSmallWrapper.defaultProps = {
    tooltip: null,
    children: null,
    className: "",
};

export default TooltipSmallWrapper;
