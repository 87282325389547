import React from "react";

import classNames from "juice-base/lib/class-names.js";

import Wrapper from "./wrapper.js";
import styles from "./styles.module.css";


const TooltipSmall = (props) => {
    const isCentered = props.position === "center";
    const isLeft = props.position === "left";
    const isRight = props.position === "right";

    const tooltipClassName = classNames({
        [styles.tooltip]: true,
        [styles.tooltipCentered]: isCentered,
        [styles.tooltipLeft]: isLeft,
        [styles.tooltipRight]: isRight,
        [styles.tooltipOffset]: props.withOffset,
    });

    const arrowClassName = classNames({
        [styles.arrowUp]: true,
        [styles.arrowUpLeft]: isLeft,
        [styles.arrowUpRight]: isRight,
    });

    return (
        <div className={tooltipClassName}>
            <div className={arrowClassName} />

            <div className={styles.content}>
                {props.children}
            </div>
        </div>
    );
};

TooltipSmall.defaultProps = {
    children: null,
    position: "center",
    withOffset: true,
};

export const TooltipSmallWrapper = Wrapper;
export default TooltipSmall;
