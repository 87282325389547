import React from "react";

import Theme from "juice-base/project/theme.js";
import Achievements from "juice-base/project/achievements.js";

import IconAchievementStoriesRead from "juice-base/icons/achievement-stories-read/index.js";
import IconAchievementStoriesReadHighContrast from "juice-base/icons/achievement-stories-read-high-contrast/index.js";
import IconAchievementStoriesReadMini from "juice-base/icons/achievement-stories-read-mini/index.js";
import IconAchievementStoriesReadMiniHighContrast from "juice-base/icons/achievement-stories-read-mini-high-contrast/index.js";

import IconAchievementQuestionsAttempted from "juice-base/icons/achievement-questions-attempted/index.js";
import IconAchievementQuestionsAttemptedHighContrast from "juice-base/icons/achievement-questions-attempted-high-contrast/index.js";
import IconAchievementQuestionsAttemptedMini from "juice-base/icons/achievement-questions-attempted-mini/index.js";
import IconAchievementQuestionsAttemptedMiniHighContrast from "juice-base/icons/achievement-questions-attempted-mini-high-contrast/index.js";


const AchievementIcon = (props) => {
    const getIcon = () => {
        const { type, isMini } = props;

        const isHighContrast = Theme.isHighContrast(props.theme);

        if (Achievements.isStoriesRead(type)) {
            if (isMini) {
                if (isHighContrast) {
                    return IconAchievementStoriesReadMiniHighContrast;
                }

                return IconAchievementStoriesReadMini;
            }

            if (isHighContrast) {
                return IconAchievementStoriesReadHighContrast;
            }

            return IconAchievementStoriesRead;
        }

        if (Achievements.isQuestionsAttempted(type)) {
            if (isMini) {
                if (isHighContrast) {
                    return IconAchievementQuestionsAttemptedMiniHighContrast;
                }

                return IconAchievementQuestionsAttemptedMini;
            }

            if (isHighContrast) {
                return IconAchievementQuestionsAttemptedHighContrast;
            }

            return IconAchievementQuestionsAttempted;
        }

        return null;
    };

    const Icon = getIcon();

    if (!Icon) {
        return null;
    }

    return (
        <Icon
            className={props.className}
        />
    );
};

AchievementIcon.defaultProps = {
    type: "",
    className: "",
    theme: "",
    isMini: false,
};

export default AchievementIcon;
