const GRADES = [
    {
        range: "5-6",
        value: "G5-G6",
        name: "Level 5-6",
    },
    {
        range: "7-8",
        value: "G7-G8",
        name: "Level 7-8",
    },
    {
        range: "9-10",
        value: "G9-G10",
        name: "Level 9-10",
    },
    {
        range: "11-12",
        value: "G11-G12",
        name: "Level 11+",
    },
];

const GRADE_GT = {
    range: "GT",
    value: "GT",
    name: "GT",
    // NOTE: dropdown support
    label: "GT",
};

const GRADES_ALL = {
    range: "all",
    value: "all",
    name: "All Reading Levels",
    label2: "All Levels Selected",
};

/* ------ */

const isGradesG5G6 = (value) => {
    if (value.indexOf("5") !== -1 || value.indexOf("6") !== -1) {
        return true;
    }

    return false;
};

const isGradesG7G8 = (value) => {
    if (value.indexOf("7") !== -1 || value.indexOf("8") !== -1) {
        return true;
    }

    return false;
};

const isGradesG9G10 = (value) => {
    if (value.indexOf("9") !== -1 || value.indexOf("10") !== -1) {
        return true;
    }

    return false;
};

const isGradesG11G12 = (value) => {
    if (value.indexOf("11") !== -1 || value.indexOf("12") !== -1) {
        return true;
    }

    return false;
};

/* ------ */

export const getGradesRanges = () => {
    return GRADES.map((val) => val.range);
};

export const getGradesOptions = () => {
    return GRADES.map((val) => ({
        value: val.value,
        name: val.name,
        range: val.range,
        // NOTE: dropdown support
        label: val.name,
    }));
};

const getGradesAllOptions = () => {
    const gradesAll = [
        ...GRADES,
    ].concat(GRADES_ALL);

    return gradesAll.map((val) => ({
        value: val.value,
        name: val.name,
        range: val.range,
        label: val.name,
        label2: val.label2 || "",
    }));
};

const filterGradesDuplicate = (grades) => {
    const ret = [];

    const allGradesChecks = [
        isGradesG5G6,
        isGradesG7G8,
        isGradesG9G10,
        isGradesG11G12,
    ];

    for (let i = 0; i < allGradesChecks.length; i += 1) {
        const isSameGrade = allGradesChecks[i];

        for (let j = 0; j < grades.length; j += 1) {
            const grade = grades[j];

            if (isSameGrade(grade)) {
                ret.push(grade);
                break;
            }
        }
    }

    return ret;
};

export const getGradesOptionsWithGT = (grades) => {
    const defaultGrades = getGradesOptions();

    if (!Array.isArray(grades)) {
        return defaultGrades;
    }

    if (grades.indexOf("GT") !== -1) {
        return defaultGrades.concat(GRADE_GT);
    }

    return defaultGrades;
};

export const getMaxGrades = () => {
    return ["G11", "G12"];
};

export const getMaxGradeValue = () => {
    return "G11-G12";
};

export const getGradesRangeByGrade = (grade) => {
    if (grade === "G5" || grade === "G6") {
        return "G5-G6";
    }

    if (grade === "G7" || grade === "G8") {
        return "G7-G8";
    }

    if (grade === "G9" || grade === "G10") {
        return "G9-G10";
    }

    if (grade === "G11" || grade === "G12") {
        return "G11-G12";
    }

    if (grade === "GT") {
        return "GT";
    }

    return "";
};

export const getGradeRange = (grades = []) => {
    if (!Array.isArray(grades)) {
        return grades;
    }

    if (grades.length === 1) {
        const grade = grades[0];

        if (grade === "G5" || grade === "G6") {
            return "5-6";
        }

        if (grade === "G7" || grade === "G8") {
            return "7-8";
        }

        if (grade === "G9" || grade === "G10") {
            return "9-10";
        }

        if (grade === "G11" || grade === "G12") {
            return "11-12";
        }
    }

    return grades.join(", ");
};

export const getGradeGroup = (grades = []) => {
    if (typeof grades !== "object") {
        return grades;
    }

    let firstGrade = "G5";

    if (grades.length > 0) {
        firstGrade = grades[0] || "G5";
    }

    if (firstGrade === "G5" || firstGrade === "G6") {
        return "5-6";
    }

    if (firstGrade === "G7" || firstGrade === "G8") {
        return "7-8";
    }

    if (firstGrade === "G9" || firstGrade === "G10") {
        return "9-10";
    }

    if (firstGrade === "G11" || firstGrade === "G12") {
        return "11+";
    }

    if (firstGrade === "GT") {
        return "GT";
    }

    return "5-6";
};

export const getGradeGroupByString = (grade) => {
    if (grade.indexOf("G5") !== -1 || grade.indexOf("G6") !== -1) {
        return "5-6";
    }

    if (grade.indexOf("G7") !== -1 || grade.indexOf("G8") !== -1) {
        return "7-8";
    }

    if (grade.indexOf("G9") !== -1 || grade.indexOf("G10") !== -1) {
        return "9-10";
    }

    if (grade.indexOf("G11") !== -1 || grade.indexOf("G12") !== -1) {
        return "11+";
    }

    if (grade.indexOf("GT") !== -1) {
        return "GT";
    }

    return "5-6";
};

export const getValidGrade = (grade = "", defaultGrade = "G7-G8") => {
    if (grade.indexOf("5") !== -1 || grade.indexOf("6") !== -1) {
        return "G5-G6";
    }

    if (grade.indexOf("7") !== -1 || grade.indexOf("8") !== -1) {
        return "G7-G8";
    }

    if (grade.indexOf("9") !== -1 || grade.indexOf("10") !== -1) {
        return "G9-G10";
    }

    if (grade.indexOf("11") !== -1 || grade.indexOf("12") !== -1) {
        return "G11-G12";
    }

    return defaultGrade;
};

export const getGradeNameByValue = (value = "") => {
    let gradeName = "";

    for (let i = 0; i < GRADES.length; i += 1) {
        if (GRADES[i].value === value) {
            gradeName = GRADES[i].name;
            break;
        }
    }

    return gradeName;
};

export const convertGradesToString = (grades = []) => {
    if (!Array.isArray(grades) || grades.length < 2) {
        return getMaxGradeValue();
    }

    return grades.join("-");
};

export const convertGradesToQueryString = (grades) => {
    if (typeof grades === "string") {
        return grades.replace("-", ",");
    }

    return "all";
};

export const findMaxGrade = (gradesArr) => {
    const gradesReversed = [...GRADES].reverse();

    for (let i = 0; i < gradesReversed.length; i += 1) {
        if (gradesArr.indexOf(gradesReversed[i].value) !== -1) {
            return gradesReversed[i].value;
        }
    }

    return "";
};

export default {
    isGradesG5G6,
    isGradesG7G8,
    isGradesG9G10,
    isGradesG11G12,

    getGradesRanges,
    getGradesOptions,
    getGradesAllOptions,
    getGradesOptionsWithGT,
    getMaxGrades,
    getMaxGradeValue,
    getGradesRangeByGrade,
    getGradeRange,

    filterGradesDuplicate,

    getGradeGroup,
    getGradeGroupByString,

    getValidGrade,
    getGradeNameByValue,

    convertGradesToString,
    convertGradesToQueryString,

    findMaxGrade,
};
