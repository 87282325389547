import React from "react";

import IconWrapper from "juice-base/icons/wrapper/index.js";


const IconUsers = (props) => {
    return (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <IconWrapper {...props}>
            {(iconProps) => (
                <svg
                    className={iconProps.className}
                    width="24"
                    height="24"
                    viewBox="0 0 43 32"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        fill="currentColor"
                        d="M30.61 18.152c2.609 1.771 4.438 4.171 4.438 7.371v5.714h7.619v-5.714c0-4.152-6.8-6.61-12.057-7.371zM27.429 16c4.209 0 7.619-3.409 7.619-7.619S31.638.762 27.429.762c-.895 0-1.733.19-2.534.457a11.39 11.39 0 0 1 0 14.324c.8.267 1.638.457 2.534.457zM16 16c4.21 0 7.619-3.409 7.619-7.619S20.21.762 16 .762s-7.619 3.41-7.619 7.619S11.79 16 16 16zm0-11.429c2.095 0 3.81 1.714 3.81 3.81s-1.714 3.81-3.81 3.81c-2.095 0-3.81-1.714-3.81-3.81s1.714-3.81 3.81-3.81zM16 17.905c-5.086 0-15.238 2.552-15.238 7.619v5.714h30.476v-5.714c0-5.067-10.152-7.619-15.238-7.619zm11.429 9.524H4.572v-1.886c.381-1.371 6.286-3.829 11.429-3.829s11.048 2.457 11.429 3.81v1.905z"
                    />
                </svg>
            )}
        </IconWrapper>
    );
};

export default IconUsers;
