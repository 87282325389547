import React, { useState, useEffect, useContext } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import array from "juice-base/lib/array.js";
import device from "juice-base/lib/device.js";
import classNames from "juice-base/lib/class-names.js";

import User from "juice-base/project/user.js";
import DailyJuice from "juice-base/project/daily-juice.js";

import actions from "juice-base/store/actions.js";
import pollsActions from "juice-base/actions/polls.js";
import vocabularyActions from "juice-base/actions/vocabulary.js";

import AudioManagerContext from "juice-base/context/audio-manager/index.js";

import RequestLoader from "juice-base/components/request-loader/index.js";
import SelectCustom from "juice-base/components/select-custom/index.js";
import SectionSponsor from "juice-base/components/section-sponsor/index.js";
import LayoutContent from "juice-base/components/layout-content/index.js";

import DailyJuiceExplore from "juice-base/business/daily-juice-explore/index.js";
import DailyJuicePolls from "juice-base/business/daily-juice-polls/index.js";
import DailyJuiceQuizResults from "juice-base/business/daily-juice-quiz-results/index.js";
import DailyJuiceAchievements from "juice-base/business/daily-juice-achievements/index.js";
import DailyJuiceCarousel from "juice-base/business/carousel-daily-juice/index.js";
import SectionAnnouncement from "juice-base/business/section-announcement/index.js";
import PopupWord from "juice-base/business/popup-word/index.js";
import PopupDailyJuiceExplore from "juice-base/business/popup-daily-juice-explore/index.js";

import UserPopupPolls from "juice-app/containers/user-popup-polls/index.js";
import Tutorial from "juice-app/containers/tutorial/index.js";
import UserFooter from "juice-app/containers/user-footer/index.js";

import api from "juice-app/api.js";
import events from "juice-app/events.js";

import styles from "./styles.module.css";


const storeSelector = (state) => ({
    session: state.user.session,
    user: state.user.user,
    student: state.student,
    guardian: state.guardian,

    dimensions: state.device.dimensions,
    theme: state.app.theme.theme,

    wordPopup: state.vocabulary.popup,
    wordsByName: state.vocabulary.wordsByName,

    extraJuices: state.extraJuices,
    polls: state.polls,
    juicesVideos: state.juicesVideos,
    sponsors: state.info.sponsors,

    playerState: state.player.playerState,

    isSponsorsLoaded: state.info.isSponsorsLoaded,
});

const StudentIndexRegular = () => {
    const audioManager = useContext(AudioManagerContext);

    const [isVisibleUserPollsPopup, setVisibleUserPollsPopup] = useState(false);

    const [explorePopupState, setExplorePopupState] = useState({
        isVisible: false,
        defaultTab: null,
    });

    const [explorePopupExtraJuice, setExplorePopupExtraJuices] = useState({
        id: null,
        isVisiblePlayer: false,
    });

    const [explorePopupVideo, setExplorePopupVideo] = useState({
        id: null,
        isLoading: false,
        video: {},
    });

    const [juiceCarousel, setJuiceCarousel] = useState({
        isLoaded: false,
        juice: {},
    });

    const dispatch = useDispatch();
    const store = useSelector(storeSelector);
    const navigate = useNavigate();

    const hasGuardianRole = User.hasRoleGuardian(store.user);

    if (User.isTypeTrial(store.user)) {
        navigate("/daily-juices");
        return null;
    }

    /* --- */

    const onLoadClosedPolls = async (params = {}) => {
        dispatch(pollsActions.loadClosedPolls({
            api,
            actions,
        }, {
            session: store.session,
            page: params.page,
        }));
    };

    const loadExtraJuices = (page = store.extraJuices.page) => {
        dispatch(actions.extraJuices.setExtraJuicesLoading());

        api.extraJuices.getExtraJuices({
            page,
        }).then((res) => {
            let extraJuices = [];

            if (res.ok) {
                extraJuices = [
                    ...store.extraJuices.extraJuices,
                    ...res.extraJuices,
                ];
            }

            // TODO: fix incorrect redux usage
            dispatch(actions.extraJuices.setExtraJuices({
                extraJuices,
                page,
                hasMorePages: res.ok ? res.hasMore : false,
            }));
        });
    };

    const loadVideos = (page) => {
        dispatch(actions.juicesVideos.setJuicesVideosLoading());

        api.dailyJuices.getJuicesVideos({
            page,
        }).then((res) => {
            let videos = [];

            if (res.ok) {
                videos = [
                    ...store.juicesVideos.videos,
                    ...res.featuredVideos,
                ];
            }

            // TODO: fix incorrect redux usage
            dispatch(actions.juicesVideos.setJuicesVideos({
                videos,
                page,
                hasMorePages: res.ok ? res.hasMore : false,
            }));
        });
    };

    const loadGuardianStudents = (req) => {
        api.students.getStudentsByGuardian(req).then((res) => {
            const students = res.students || [];

            let selectedStudentId = store.guardian.selectedStudent;

            if (!selectedStudentId) {
                selectedStudentId = students[0] ? students[0].id || null : null;
            }

            dispatch(actions.guardian.setSelectedStudent({
                selectedStudent: selectedStudentId,
            }));

            dispatch(actions.guardian.setStudents({
                students,
            }));
        });
    };

    const loadStudentClassInfo = (req) => {
        api.students.getStudentClassInfo(req).then((res) => {
            if (res.ok) {
                const classData = res.data || {};

                dispatch(actions.student.setStudentSponsor({
                    sponsor: {
                        imageId: classData.sponsorImageId || "",
                        imageUrl: classData.sponsorImageUrl || "",

                        sponsoredBy: classData.sponsoredBy || "",
                        sponsorLink: classData.sponsorLink || "",

                        content: classData.sponsorContent || "",
                    },
                }));

                dispatch(actions.student.setStudentAnnouncement({
                    announcement: {
                        title: classData.announcementTitle || "Class announcement",
                        content: classData.announcementContent || "",
                    },
                }));
            }
        });
    };

    const loadVideoById = (id) => {
        setExplorePopupState({
            isVisible: true,
            defaultTab: "videos",
        });

        setExplorePopupVideo({
            isLoading: true,
            id,
            video: {},
        });

        api.videos.getJuiceVideo({
            videoId: id,
            session: store.session,
        }).then((res) => {
            setExplorePopupVideo((prev) => ({
                ...prev,
                isLoading: false,
                video: res.ok ? res.video : {},
            }));
        });
    };

    const onWordClick = (word) => {
        dispatch(vocabularyActions.openPopup({
            api,
            actions,
        }, {
            session: store.session,
            word,
        }));
    };

    const onCloseWordPopup = () => {
        dispatch(vocabularyActions.closePopup({ actions }));
    };

    const onShowExtraJuiceStoryPlayer = (extraJuiceId) => {
        setExplorePopupExtraJuices((prev) => ({
            ...prev,
            isVisiblePlayer: true,
        }));

        audioManager.loadExtraJuice(extraJuiceId);
    };

    const onHideExtraJuiceStoryPlayer = () => {
        setExplorePopupExtraJuices((prev) => ({
            ...prev,
            isVisiblePlayer: false,
        }));
    };

    const onChangeGuardianStudent = (value) => {
        events.guardian.homeSelectStudent({
            session: store.session,
            studentId: value,
        });

        dispatch(actions.guardian.setSelectedStudent({
            selectedStudent: value,
        }));

        setJuiceCarousel((prev) => ({
            ...prev,
            isLoaded: false,
        }));

        setJuiceCarousel((prev) => ({
            ...prev,
            isLoaded: false,
        }));

        dispatch(actions.guardian.setSelectedStudent({
            selectedStudent: value,
        }));
    };

    const onExploreViewMoreClick = (tab) => {
        setExplorePopupState({
            isVisible: true,
            defaultTab: tab,
        });
    };

    const onOpenUserPollsPopup = () => {
        setVisibleUserPollsPopup(true);
    };

    const onCloseUserPollsPopup = () => {
        setVisibleUserPollsPopup(false);
    };

    const onCloseExplorePopup = () => {
        setExplorePopupState({
            isVisible: false,
            defaultTab: null,
        });

        setExplorePopupVideo({
            id: null,
            isLoading: false,
            video: {},
        });

        setExplorePopupExtraJuices({
            id: null,
            isVisiblePlayer: false,
        });
    };

    const onExploreVideoClick = (videoId) => {
        loadVideoById(videoId);
    };

    const onExploreExtraJuiceClick = (extraJuiceId) => {
        setExplorePopupState({
            isVisible: true,
            defaultTab: "extraJuice",
        });

        setExplorePopupExtraJuices({
            id: extraJuiceId,
            isVisiblePlayer: false,
        });
    };

    const onExploreBackToList = () => {
        setExplorePopupExtraJuices({
            id: null,
            isVisiblePlayer: false,
        });

        setExplorePopupVideo({
            id: null,
            isLoading: false,
            video: {},
        });
    };

    const onLoadMoreExtraJuices = () => {
        loadExtraJuices(store.extraJuices.page + 1);
    };

    const onLoadMoreVideos = () => {
        loadVideos(store.juicesVideos.page + 1);
    };

    const getExtraJuiceById = (id) => {
        return array.findOneById(store.extraJuices.extraJuices, id);
    };

    const getVideoById = (id) => {
        for (let i = 0; i < store.juicesVideos.videos.length; i += 1) {
            if (store.juicesVideos.videos[i].videoID === id) {
                let poster = null;

                if (store.juicesVideos.videos[i]?.featuredImage?.sizes?.medium?.src) {
                    poster = store.juicesVideos.videos[i].featuredImage.sizes.medium.src;
                }

                const captionSrc = api.videos.getVideoCaptionURL({
                    session: store.session,
                    id: store.juicesVideos.videos[i].videoID,
                });

                return {
                    id: store.juicesVideos.videos[i].videoID,
                    title: store.juicesVideos.videos[i].newsTitle,
                    poster,
                    captionSrc,
                };
            }
        }

        return null;
    };

    /* --- */

    useEffect(() => { // eslint-disable-line react-hooks/rules-of-hooks
        if (!store.polls.isClosedPollsLoaded) {
            onLoadClosedPolls({
                page: 0,
            });
        }

        return () => {
            dispatch(vocabularyActions.closePopup({ actions }));
            dispatch(actions.polls.clearClosedPolls());
        };
    }, []);

    useEffect(() => { // eslint-disable-line react-hooks/rules-of-hooks
        const req = {
            session: store.session,
            studentId: hasGuardianRole ? store.guardian.selectedStudent : null,
        };

        if (!store.guardian.isStudentsLoaded) {
            if (hasGuardianRole) {
                loadGuardianStudents(req);
            } else {
                dispatch(actions.guardian.setStudents({
                    students: [],
                }));
            }
        } else {
            loadStudentClassInfo(req);

            api.dailyJuices.getLastJuice(req).then((res) => {
                setJuiceCarousel({
                    isLoaded: true,
                    juice: res.ok ? res.juice : null,
                });
            });

            if (store.extraJuices.page === 0 && !store.extraJuices.isExtraJuicesLoaded) {
                loadExtraJuices();
            }

            if (store.juicesVideos.page === 0 && !store.juicesVideos.isVideosLoaded) {
                loadVideos(store.juicesVideos.page);
            }

            api.students.getStudentQuizChart(req).then((res) => {
                dispatch(actions.student.setStudentQuizResults({
                    quizResults: res.ok ? res.data : [],
                }));
            });
        }
    }, [store.guardian]);

    /* --- */

    const renderTutorial = () => {
        if (hasGuardianRole) {
            return (
                <Tutorial name="guardian-index" />
            );
        }

        return (
            <Tutorial name="student-index" />
        );
    };

    const renderGuardianStudentsList = () => {
        if (!hasGuardianRole) {
            return null;
        }

        const studentsValues = [];

        store.guardian.students.forEach((student) => {
            studentsValues.push({ value: student.ID, label: student.fullName });
        });

        return (
            <div className={styles.guardianStudentsList}>
                <SelectCustom
                    selected={store.guardian.selectedStudent}
                    options={studentsValues}
                    dataComment="guardian-students-list"
                    onSelect={onChangeGuardianStudent}
                    withShadow
                />
            </div>
        );
    };

    const renderJuiceCard = () => {
        let stories = [];

        if (juiceCarousel?.juice?.stories?.juice) {
            const studentStories = DailyJuice.getStudentStories(juiceCarousel.juice.stories.juice);

            stories = studentStories.map((juice) => {
                const juiceId = juiceCarousel.juice.id;

                return {
                    id: juice.id,
                    juiceLink: `/daily-juices/${juiceId}`,
                    date: juiceCarousel.juice.juiceDate,
                    featuredImage: juice.featuredImage,
                    backgroundImage: juice.backgroundImage,
                    title: juice.title,
                };
            });
        }

        return (
            <DailyJuiceCarousel
                items={stories}
            />
        );
    };

    const renderSponsor = () => {
        if (!store.isSponsorsLoaded) {
            return null;
        }

        return (
            <SectionSponsor
                sponsor={store.sponsors}
                onSponsorClick={() => {
                    // TODO:
                }}
            />
        );
    };

    const renderAnnouncement = () => {
        if (!store.student.isAnnouncementLoaded) {
            return null;
        }

        const cnt = store.student.announcement.content || "";
        const title = store.student.announcement.title || "";

        if (!cnt) {
            return null;
        }

        return (
            <SectionAnnouncement
                content={cnt}
                title={title}
            />
        );
    };

    const renderAchievements = () => {
        return (
            <DailyJuiceAchievements
                theme={store.theme}
            />
        );
    };

    const renderQuizResults = () => {
        return (
            <DailyJuiceQuizResults
                quizResults={store.student.quizResults}
                isMobile={store.dimensions.width < 430}
                onViewMore={() => {
                    navigate("/daily-juices");
                }}
            />
        );
    };

    const renderUserPollsPopup = () => {
        if (!isVisibleUserPollsPopup) {
            return null;
        }

        return (
            <UserPopupPolls
                onClose={onCloseUserPollsPopup}
            />
        );
    };

    const renderExplorePopup = () => {
        if (!explorePopupState.isVisible) {
            return null;
        }

        let selectedExtraJuice = null;
        let selectedVideo = null;

        if (explorePopupExtraJuice.id) {
            selectedExtraJuice = getExtraJuiceById(explorePopupExtraJuice.id);
        }

        if (explorePopupVideo.id) {
            const sVideo = getVideoById(explorePopupVideo.id);
            selectedVideo = {
                ...sVideo,
                ...explorePopupVideo.video,
                isVideoLoading: explorePopupVideo.isLoading,
            };
        }

        const trackGroupName = "extraJuices";
        const trackId = explorePopupExtraJuice.id;

        let audioData = null;

        if (explorePopupExtraJuice.isVisiblePlayer) {
            audioData = store.playerState?.[trackGroupName]?.[trackId] || null;
        }

        return (
            <PopupDailyJuiceExplore
                extraJuices={store.extraJuices.extraJuices}
                videos={store.juicesVideos.videos}
                isDefaultVideo={!device.isChrome}
                selectedVideo={selectedVideo || {}}
                defaultTab={explorePopupState.defaultTab}
                selectedExtraJuice={selectedExtraJuice || {}}
                showLoadMoreExtraJuices={store.extraJuices.hasMorePages}
                showLoadMoreVideos={store.juicesVideos.hasMorePages}
                isExtraJuicesLoading={store.extraJuices.isExtraJuicesLoading}
                isVideosLoading={store.juicesVideos.isVideosLoading}
                audio={audioData}
                onBackToList={onExploreBackToList}
                onExtraJuiceClick={onExploreExtraJuiceClick}
                onVideoClick={onExploreVideoClick}
                onLoadMoreVideos={onLoadMoreVideos}
                onLoadMoreExtraJuices={onLoadMoreExtraJuices}
                onWordClick={onWordClick}
                onAudioPlay={() => {
                    onShowExtraJuiceStoryPlayer(explorePopupExtraJuice.id);
                }}
                onPlay={() => {
                    audioManager.play(trackGroupName, trackId);
                }}
                onPause={() => {
                    audioManager.pause(trackGroupName, trackId);
                }}
                onRewind={() => {
                    audioManager.rewind(trackGroupName, trackId);
                }}
                onForward={() => {
                    audioManager.forward(trackGroupName, trackId);
                }}
                onChangeRate={(rate) => {
                    audioManager.changeRate(trackGroupName, trackId, rate);
                }}
                onPlayerClose={() => {
                    audioManager.stop(trackGroupName, trackId);
                    onHideExtraJuiceStoryPlayer();
                }}
                onClose={onCloseExplorePopup}
            />
        );
    };

    const renderExplore = () => {
        return (
            <DailyJuiceExplore
                extraJuices={store.extraJuices.extraJuices}
                videos={store.juicesVideos.videos}
                isExtraJuicesLoaded={store.extraJuices.isExtraJuicesLoaded}
                isVideosLoaded={store.juicesVideos.isVideosLoaded}
                onViewMore={onExploreViewMoreClick}
                onVideoClick={onExploreVideoClick}
                onExtraJuiceClick={onExploreExtraJuiceClick}
            />
        );
    };

    const renderLatestPoll = () => {
        const poll = store.polls?.closedPolls?.[0] || null;

        return (
            <DailyJuicePolls
                poll={poll}
                isLoading={store.polls.isClosedPollsLoading && !store.polls.isClosedPollsLoaded}
                onViewMore={onOpenUserPollsPopup}
            />
        );
    };

    const renderWordPopup = () => {
        if (!store.wordPopup.isVisible) {
            return null;
        }

        const trackGroupName = "words";
        const audioData = store.playerState?.[trackGroupName] || {};

        return (
            <PopupWord
                wordsByName={store.wordsByName}
                wordPopup={store.wordPopup}
                hideScrollbar={false}
                audio={audioData}
                onAudioLoad={(txt) => {
                    audioManager.loadWord(txt);
                }}
                onAudioPlay={(txt) => {
                    audioManager.play(trackGroupName, txt);
                }}
                onAudioStop={(txt) => {
                    audioManager.stop(trackGroupName, txt);
                }}
                onAudioStopAll={(words) => {
                    audioManager.stopAllTracks(trackGroupName, words);
                }}
                onClose={onCloseWordPopup}
            />
        );
    };

    if (!juiceCarousel.isLoaded) {
        return (
            <RequestLoader />
        );
    }

    const indexClassName = classNames({
        [styles.index]: true,
        [styles.indexWithGuardian]: hasGuardianRole,
    });

    return (
        <>
            {renderTutorial()}
            {renderExplorePopup()}
            {renderUserPollsPopup()}
            {renderWordPopup()}

            <LayoutContent>
                <div className={indexClassName}>
                    {renderGuardianStudentsList()}

                    <div className={styles.juiceCarousel}>
                        {renderJuiceCard()}
                    </div>
                    <div className={styles.bottomWidgets}>
                        {renderExplore()}
                        {renderLatestPoll()}
                    </div>
                    <div className={styles.asideWidgets}>
                        {renderSponsor()}
                        {renderAnnouncement()}
                        {renderAchievements()}
                        {renderQuizResults()}
                    </div>
                </div>
            </LayoutContent>

            <UserFooter />
        </>
    );
};


export default StudentIndexRegular;
