import React, { useState, useEffect, useRef } from "react";

import classNames from "juice-base/lib/class-names.js";
import date from "juice-base/lib/date.js";

import IconArrowRight from "juice-base/icons/arrow-right/index.js";
import IconClose from "juice-base/icons/close/index.js";

import IconClickable from "juice-base/components/icon-clickable/index.js";
import Tabs from "juice-base/components/tabs/index.js";
import ButtonBig from "juice-base/components/button-big/index.js";
import ButtonCircle from "juice-base/components/button-circle/index.js";

import styles from "./styles.module.css";


const DatepickerCustom = (props) => {
    const tabs = [
        { value: "date", label: "Date" },
        { value: "range", label: "Range" },
    ];

    const calendarRef = useRef(null);

    const [selectedTab, setSelectedTab] = useState(() => {
        if (props.isRangeTabByDefault) {
            return tabs[1].value;
        }

        return tabs[0].value;
    });

    const [selectedMonthDate, setSelectedMonthDate] = useState(() => {
        if (props.isRangeTabByDefault && props.defaultRange.start) {
            return date.getStartOfMonthDate(props.defaultRange.start);
        }

        if (props.defaultDate) {
            return date.getStartOfMonthDate(props.defaultDate);
        }

        return date.getStartOfMonthDate(new Date());
    });

    /* ------ */

    const [selectedDate, setSelectedDate] = useState(() => {
        if (props.defaultDate) {
            return props.defaultDate;
        }

        return null;
    });

    const [selectedRange, setSelectedRange] = useState(() => {
        const { start, end } = props.defaultRange;

        return {
            start: start || null,
            end: end || null,
            lastChange: "",
        };
    });

    const [lastHoveredDay, setLastHoveredDay] = useState(null);

    /* ------ */

    const getDaysBeforeSunday = (firstDateOfMonth) => {
        const sundayDate = date.getSunday(firstDateOfMonth);

        return date.getDiffInDays(sundayDate, firstDateOfMonth);
    };

    /* ------ */

    const onGoToPrevMonth = () => {
        const prevMonth = date.getPrevMonth(selectedMonthDate);

        setSelectedMonthDate(prevMonth);
    };

    const onGoToNextMonth = () => {
        const nextMonth = date.getNextMonth(selectedMonthDate);

        setSelectedMonthDate(nextMonth);
    };

    const onChangeStartDate = (d, clearEnd) => {
        setSelectedRange((prev) => ({
            ...prev,
            start: d,
            end: clearEnd ? null : prev.end,
            lastChange: "start",
        }));
    };

    const onChangeEndDate = (d) => {
        setSelectedRange((prev) => ({
            ...prev,
            end: d,
            lastChange: "end",
        }));
    };

    const onDateClick = (d, isDisabled) => {
        if (isDisabled) {
            return;
        }

        if (selectedTab === "date") {
            setSelectedDate(d);
            return;
        }

        const { start, end, lastChange } = selectedRange;

        if (!lastChange) {
            if ((end && d > end) || (end && date.isDatesSame(d, end))) {
                onChangeStartDate(d, true);
            } else {
                onChangeStartDate(d, false);
            }
            return;
        }

        if (lastChange === "start") {
            if (d < start) {
                onChangeStartDate(d, true);
                return;
            }

            onChangeEndDate(d);
            return;
        }

        if (d >= end) {
            onChangeStartDate(d, true);
            return;
        }

        onChangeStartDate(d, false);
    };

    const onSave = () => {
        if (selectedTab === "date") {
            props.onSaveDate(selectedDate);
            return;
        }

        props.onSaveRange({
            startDate: selectedRange.start,
            endDate: selectedRange.end,
        });
    };

    const onClose = () => {
        props.onClose();
    };

    /* ----- */

    useEffect(() => {
        const globalClose = (evt) => {
            if (calendarRef?.current?.contains
                && calendarRef.current.contains(evt.target)) {
                return;
            }

            onClose();
        };

        if (!calendarRef.current) {
            return () => { };
        }

        if (document) {
            document.addEventListener("click", globalClose, true);
        }

        return () => {
            if (document) {
                document.removeEventListener("click", globalClose, true);
            }
        };
    }, []);

    /* ----- */

    const renderTabs = () => {
        return (
            <Tabs
                onlyTabs
                isWhiteTheme
                tabs={tabs}
                selectedTab={selectedTab}
                onChange={setSelectedTab}
            />
        );
    };

    const renderControls = () => {
        const { start, end } = selectedRange;

        let isSaveDisabled = true;

        if (selectedTab === "date" && selectedDate) {
            isSaveDisabled = false;
        } else if (selectedTab === "range" && start && end) {
            isSaveDisabled = false;
        }

        return (
            <div className={styles.controls}>
                <ButtonBig
                    uppercase
                    disabled={isSaveDisabled}
                    onClick={onSave}
                >
                    Save
                </ButtonBig>
            </div>
        );
    };

    const renderClose = () => {
        if (!props.withClose) {
            return null;
        }

        return (
            <IconClickable
                className={styles.close}
                onClick={onClose}
            >
                <IconClose
                    title="Close"
                    isWhite
                />
            </IconClickable>
        );
    };

    const renderSelectedRange = () => {
        const { start, end } = selectedRange;

        let range = "";

        if (!start) {
            range = "Pick a start date";
        } else if (!end) {
            range = "Pick an end date";
        } else {
            const sDate = date.tryFormatDate(start, date.formatFullMonthDayDate);
            const eDate = date.tryFormatDate(end, date.formatFullMonthDayDate);

            range = `${sDate} - ${eDate}`;
        }

        return (
            <div>{range}</div>
        );
    };

    const renderSelectedDate = () => {
        if (selectedTab === "range") {
            return renderSelectedRange();
        }

        const d = selectedDate
            ? date.tryFormatDate(selectedDate, date.formatDayDate)
            : "Pick a date";

        return (
            <div>{d}</div>
        );
    };

    const renderCalendarNavigation = () => {
        const currMonth = date.tryFormatDate(selectedMonthDate, date.formatMonthYearDate);

        return (
            <div className={styles.calendarNavigation}>
                <ButtonCircle
                    icon={<IconArrowRight />}
                    onClick={onGoToPrevMonth}
                    isImageBackwards
                />
                <div className={styles.calendarNavigationMonth}>
                    {currMonth}
                </div>
                <ButtonCircle
                    icon={<IconArrowRight />}
                    onClick={onGoToNextMonth}
                />
            </div>
        );
    };

    const renderCalendarWeekdays = () => {
        const daysOfWeek = ["S", "M", "T", "W", "T", "F", "S"];

        return (
            <div className={styles.daysRow}>
                {daysOfWeek.map((day, i) => ((
                    <div key={`day-${i}`} className={styles.dayOfWeek}>
                        {day}
                    </div>
                )))}
            </div>
        );
    };

    const renderCalendarMonthDays = () => {
        const { start, end } = selectedRange;

        const days = [];

        const daysInMonth = date.getDaysInMonth(selectedMonthDate);

        const firstDate = new Date(
            selectedMonthDate.getFullYear(),
            selectedMonthDate.getMonth(),
            1,
        );

        for (let i = 1; i < daysInMonth + 1; i += 1) {
            const d = new Date(selectedMonthDate.getFullYear(), selectedMonthDate.getMonth(), i);

            let isSelected = false;
            let isBetween = false;
            let isDisabled = false;

            if (selectedTab === "date") {
                isSelected = selectedDate && date.isDatesSame(selectedDate, d);
            } else {
                if ((start && date.isDatesSame(start, d)) || (end && date.isDatesSame(end, d))) {
                    isSelected = true;
                }

                if (start && end && ((d >= start || isSelected) && (d <= end || isSelected))) {
                    isBetween = true;
                }

                if (start && !end && (d <= lastHoveredDay && d >= start)) {
                    isBetween = true;
                }
            }

            if (props.isWeekendsDisabled && date.isWeekend(d)) {
                isDisabled = true;
            } else if (props.minDate && d < props.minDate) {
                isDisabled = true;
            } else if (props.maxDate && d > props.maxDate) {
                isDisabled = true;
            }

            const dayContainerClassName = classNames({
                [styles.dayContainer]: true,
                [styles.dayContainerDisabled]: isDisabled,
                [styles.dayContainerBetweenRange]: isBetween,
                [styles.dayContainerBetweenRangeHovered]: start && !end && isBetween
                    && date.isDatesSame(d, lastHoveredDay),
                [styles.dayContainerBetweenRangeFirst]: isBetween && date.isDatesSame(d, start),
                [styles.dayContainerBetweenRangeLast]: isBetween && date.isDatesSame(d, end),
            });

            const dayClassName = classNames({
                [styles.day]: true,
                [styles.dayToday]: date.isToday(d),
                [styles.daySelected]: isSelected,
                [styles.dayDisabled]: isDisabled,
            });

            days.push(
                <div
                    className={dayContainerClassName}
                    onMouseOver={() => {
                        setLastHoveredDay(d);
                    }}
                    onFocus={() => {
                        setLastHoveredDay(d);
                    }}
                    onClick={() => {
                        onDateClick(d, isDisabled);
                    }}
                    onKeyPress={() => {
                        onDateClick(d, isDisabled);
                    }}
                    tabIndex="-1"
                    role="button"
                >
                    <div className={dayClassName}>
                        {i}
                    </div>
                </div>,
            );
        }

        const daysBeforeSunday = getDaysBeforeSunday(firstDate);

        const rows = [];
        const totalRows = Math.ceil((daysInMonth + daysBeforeSunday) / 7);

        let lastIndex = 0;

        for (let i = 0; i < totalRows; i += 1) {
            const rowDays = [];

            for (let j = 0; j < 7; j += 1) {
                if (i === 0 && j < daysBeforeSunday) {
                    rowDays.push(<div />);
                } else {
                    rowDays.push(days[lastIndex]);
                    lastIndex += 1;
                }
            }

            rows.push(
                <div className={styles.daysRow}>
                    {rowDays}
                </div>,
            );
        }

        return (
            <div
                className={styles.rows}
                onMouseLeave={() => {
                    setLastHoveredDay(null);
                }}
            >
                {rows}
            </div>
        );
    };

    const pickerClassName = classNames({
        [styles.container]: true,
        [props.datepickerClassName]: props.datepickerClassName,
    });

    return (
        <div
            ref={calendarRef}
            className={pickerClassName}
        >
            <div className={styles.header}>
                <div className={styles.headerDate}>
                    <div className={styles.headerDateTitle}>
                        <div>
                            {`Select ${selectedTab === "range" ? "Range" : "Date"}`}
                        </div>
                        {renderSelectedDate()}
                    </div>
                    {renderClose()}
                </div>
                {renderTabs()}
            </div>

            {renderCalendarNavigation()}
            {renderCalendarWeekdays()}
            {renderCalendarMonthDays()}
            {renderControls()}
        </div>
    );
};

DatepickerCustom.defaultProps = {
    datepickerClassName: "",

    minDate: null,
    maxDate: null,

    defaultDate: null,
    defaultRange: {
        start: null,
        end: null,
    },

    onSaveDate: () => { },
    onSaveRange: () => { },
    onClose: () => { },

    isWeekendsDisabled: false,
    isRangeTabByDefault: false,
    withClose: true,
};

export default DatepickerCustom;
