import React, { useRef, useState, useEffect } from "react";

import classNames from "juice-base/lib/class-names.js";

import TooltipInfoWithIcon from "juice-base/components/tooltip-info-with-icon/index.js";
import IconCaret from "juice-base/icons/caret/index.js";
import IconInformation from "juice-base/icons/information/index.js";

import scrollbarStyles from "juice-base/components/scrollbar/styles.module.css";
import styles from "./styles.module.css";

import Option from "./option.js";
import OptionWithLabels from "./option-with-labels.js";


const SelectCustom = (props) => {
    const selectCustom = useRef(null);
    const [isOpened, setIsOpened] = useState(false);

    /* --- */

    const onToggle = () => {
        if (props.disabled) {
            return;
        }

        setIsOpened((val) => !val);
    };

    const onSelect = (evt, opt) => {
        setIsOpened(false);
        props.onSelect(opt.value);
    };

    const onSelectedValueIconClick = (evt) => {
        evt.stopPropagation();
        props.onIconClick();
    };

    /* --- */

    useEffect(() => {
        const globalClose = (evt) => {
            if (selectCustom?.current?.contains
                && selectCustom.current.contains(evt.target)) {
                return;
            }

            setIsOpened(() => false);
        };

        if (document) {
            document.addEventListener("click", globalClose, false);
        }

        return () => {
            if (document) {
                document.removeEventListener("click", globalClose, false);
            }
        };
    }, []);

    /* --- */

    const renderSelectedValueIcon = (iconType) => {
        if (props.tooltip) {
            return (
                <TooltipInfoWithIcon
                    tooltip={props.tooltip}
                    position="center"
                    isDark
                />
            );
        }

        if (iconType === "info") {
            return (
                <div
                    className={styles.optionSelectedValueIcon}
                    onClick={onSelectedValueIconClick}
                    onKeyPress={onSelectedValueIconClick}
                    role="button"
                    tabIndex="-1"
                >
                    <IconInformation
                        className={styles.optionSelectedValueIconImg}
                        isSky
                    />
                </div>
            );
        }

        return null;
    };

    const renderOptionSelectedIcon = () => {
        if (!props.icon) {
            return null;
        }

        return (
            <div className={styles.optionSelectedIcon}>
                {props.icon}
            </div>
        );
    };

    const renderOptionSelectedCaret = () => {
        const imgClassName = classNames({
            [styles.optionCaretImg]: true,
            [styles.optionSelectedCaretImgUp]: isOpened,
        });

        return (
            <div className={styles.optionSelectedCaret}>
                <IconCaret
                    className={imgClassName}
                />
            </div>
        );
    };

    const renderOptionSelectedValue = () => {
        let selectedOpt = null;

        for (let i = 0; i < props.options.length; i += 1) {
            const opt = props.options[i];

            if (opt.value === props.selected) {
                selectedOpt = opt;
                break;
            }
        }

        const label = selectedOpt
            ? selectedOpt.label
            : props.defaultValueLabel;

        if (props.withLabel2 || props.withSelectOptionLabel2) {
            const label2 = selectedOpt?.label2 || "";

            const optionSelectedClassName = classNames({
                [styles.optionSelectedValueLabel]: true,
                [styles.optionSelectedValueLabels]: label2,
            });

            return (
                <div className={styles.optionSelectedValue}>
                    <div className={optionSelectedClassName}>
                        <div>
                            {label}
                        </div>
                        <div className={styles.optionSelectedValueLabel2}>
                            {label2}
                        </div>
                    </div>
                    {renderSelectedValueIcon(selectedOpt ? selectedOpt.icon : "")}
                </div>
            );
        }

        return (
            <div className={styles.optionSelectedValue}>
                {label}
                {renderSelectedValueIcon(selectedOpt ? selectedOpt.icon : "")}
            </div>
        );
    };

    const renderOptions = () => {
        if (!isOpened) {
            return null;
        }

        let options = [];

        if (props.options.length === 0) {
            const optClassName = classNames({
                [styles.option]: true,
                [styles.optionWithIcon]: props.icon,
            });

            const option = (
                <div
                    className={optClassName}
                    onClick={() => { onToggle(); }}
                    onKeyPress={() => { onToggle(); }}
                    tabIndex="-1"
                    role="button"
                >
                    {props.noOptionsText}
                </div>
            );

            options.push(option);
        } else {
            options = props.options.map((opt) => {
                const isSelected = props.selected === opt.value;

                if (props.withLabel2) {
                    return (
                        <OptionWithLabels
                            option={opt}
                            isSelected={isSelected}
                            withIcon={props.icon}
                            onSelect={onSelect}
                        />
                    );
                }

                return (
                    <Option
                        key={`option-${opt.value}`}
                        option={opt}
                        isSelected={isSelected}
                        withIcon={props.icon}
                        orangeTheme={props.orangeTheme}
                        small={props.small}
                        onSelect={onSelect}
                    />
                );
            });
        }

        const optionsNum = options.length;

        const optionsClassName = classNames({
            [styles.options]: true,
            [styles.optionsWithBorderRadius]: props.withBorderRadius,
            [styles.optionsWithScroll]: optionsNum > 4 && props.withScroll,
            [scrollbarStyles.scrollbar]: optionsNum > 4 && props.withScroll,
        });

        return (
            <div className={optionsClassName}>
                {options}
            </div>
        );
    };

    const selectClassName = classNames({
        [styles.select]: true,
        [styles.selectWithBorderRadius]: props.withBorderRadius,
        [styles.selectWithBorder]: props.withBorder,
        [styles.selectWithShadow]: props.withShadow,
        [styles.selectDisabled]: props.disabled,
    });

    const optSelectedClassName = classNames({
        [styles.optionSelected]: true,
        [styles.optionSelectedWithBorderRadius]: props.withBorderRadius,
        [styles.optionSelectedSmall]: props.small,
        [styles.optionSelectedWithIcon]: props.icon,
        [styles.optionSelectedDisabled]: props.disabled,
    });

    return (
        <div
            ref={selectCustom}
            className={selectClassName}
            data-comment={props.dataComment}
        >
            <div
                className={optSelectedClassName}
                onClick={onToggle}
                onKeyPress={onToggle}
                tabIndex="-1"
                role="button"
            >
                {renderOptionSelectedIcon()}
                {renderOptionSelectedValue()}
                {renderOptionSelectedCaret()}
            </div>

            {renderOptions()}
        </div>
    );
};

SelectCustom.defaultProps = {
    selected: null,
    options: [],
    icon: null,
    defaultValueLabel: "Select ...",
    noOptionsText: "No options",
    dataComment: "",
    onSelect: () => { },
    onIconClick: () => { },
    disabled: false,
    withLabel2: false,
    withSelectOptionLabel2: false,
    withScroll: true,
    withBorder: true,
    withShadow: false,
    orangeTheme: false,
    withBorderRadius: false,
    small: false,
    tooltip: "",
};

export default SelectCustom;
