const ACHIEVEMENTS = {
    storiesRead: {
        value: "storiesRead",
        label: "Stories Read",
    },
    questionsAttempted: {
        value: "questionsAttempted",
        label: "Questions Attempted",
    },
};

/* ------ */

const getAchievements = () => {
    return { ...ACHIEVEMENTS };
};

const getAchievementsValues = () => {
    const keys = Object.keys(ACHIEVEMENTS);

    return keys.map((key) => ACHIEVEMENTS[key]);
};

/* ------ */

const isStoriesRead = (value) => {
    return value === ACHIEVEMENTS.storiesRead.value;
};

const isQuestionsAttempted = (value) => {
    return value === ACHIEVEMENTS.questionsAttempted.value;
};

/* ------ */

export default {
    getAchievements,
    getAchievementsValues,

    isStoriesRead,
    isQuestionsAttempted,
};
