import React from "react";

import classNames from "juice-base/lib/class-names.js";

import LoaderSmall from "juice-base/components/loader-small/index.js";
import Text from "juice-base/components/text/index.js";

import styles from "./styles.module.css";


const Poll = (props) => {
    const {
        isDisabled,
        isLoading,
        isOutdated,
        isSelected,
        isVisibleResults,
    } = props;

    const onSelect = () => {
        if (isLoading || isDisabled) {
            return;
        }

        props.onSelect();
    };

    const renderBar = () => {
        const barClassName = classNames({
            [styles.bar]: true,
            [styles.barSelected]: isSelected,
        });

        const barStyle = {};

        if (isVisibleResults && !isLoading) {
            barStyle.width = `${props.percent}%`;
        }

        return (
            <div className={styles.barContainer}>
                <div
                    className={barClassName}
                    style={barStyle}
                />
            </div>
        );
    };

    const renderPercent = () => {
        if (!isVisibleResults || isLoading) {
            return null;
        }

        const percent = Number(props.percent).toFixed(0);

        return (
            <div className={styles.percent}>
                {`${percent}%`}
            </div>
        );
    };

    const renderAnswer = () => {
        if (isLoading) {
            return (
                <div className={styles.answer}>
                    <LoaderSmall />
                </div>
            );
        }

        return (
            <Text className={styles.answer}>
                {props.answer}
            </Text>
        );
    };

    const pollClassName = classNames({
        [styles.poll]: true,
        [styles.pollSelected]: isSelected,
        [styles.pollDisabled]: isLoading || isDisabled || isOutdated,
    });

    return (
        <div
            className={pollClassName}
            onClick={onSelect}
            onKeyPress={onSelect}
            tabIndex="-1"
            role="button"
        >
            {renderBar()}
            {renderAnswer()}
            {renderPercent()}
        </div>
    );
};

Poll.defaultProps = {
    isOutdated: false,
    isDisabled: false,
    isLoading: false,
    isSelected: false,
    isVisibleResults: false,
    percent: 0,
    answer: "",
    onSelect: () => { },
};

export default Poll;
