import React, {
    useRef,
    useEffect,
    useState,
    useCallback,
    Suspense,
} from "react";

import {
    Outlet,
    useLocation,
    useParams,
    useNavigate,
    matchPath,
} from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";

import text from "juice-base/text/index.js";

import User from "juice-base/project/user.js";
import Classes from "juice-base/project/classes.js";

import urls from "juice-base/lib/urls.js";
import document from "juice-base/lib/document.js";
import storage from "juice-base/lib/storage/index.js";
import scroll from "juice-base/lib/scroll.js";
import actions from "juice-base/store/actions.js";
import appActions from "juice-base/actions/app.js";
import actionsUser from "juice-base/actions/user.js";
import actionsTeacher from "juice-base/actions/teacher.js";

import useDimensions from "juice-base/hooks/use-dimensions/index.js";
import useSnackbar from "juice-base/hooks/use-snackbar/index.js";
import usePopup from "juice-base/hooks/use-popup/index.js";

import StripeContext from "juice-base/context/stripe/index.js";

import ErrorBoundary from "juice-base/components/error-boundary/index.js";
import ErrorBoundaryWithValue from "juice-base/components/error-boundary-with-value/index.js";
import Layout from "juice-base/components/layout/index.js";
import RequestLoader from "juice-base/components/request-loader/index.js";

import Snackbar from "juice-base/components/snackbar/index.js";

import PopupEmailChange from "juice-base/business/popup-email-change/index.js";
import PopupNewPassword from "juice-base/business/popup-new-password/index.js";
import AchievementsWidget from "juice-base/business/achievements-widget/index.js";

import PopupMessages from "juice-app/containers/popup-messages/index.js";
import TeacherPopupWelcome from "juice-app/containers/teacher-popup-welcome/index.js";
import UserPopupAccount from "juice-app/containers/user-popup-account/index.js";
import UserPopupConfirmExpiration from "juice-app/containers/user-popup-confirm-expiration/index.js";
import PopupConfirmClassesEndDate, {
    usePopupConfirmClassesEndDate,
} from "juice-base/business/popup-confirm-classes-end-date/index.js";

import settings from "juice-app/settings.js";
import api from "juice-app/api.js";
import events from "juice-app/events.js";


const storeSelector = (state) => ({
    libs: state.app.libs,
    version: state.app.version,
    isSocketMonitorOnline: state.monitors.isSocketMonitorOnline,
    theme: state.app.theme,
    session: state.user.session,
    user: state.user.user,
    isTeacherWelcomePopupOpen: state.user.isTeacherWelcomePopupOpen,
    teacher: state.teacher,
    siteDate: state.info.siteDate,
    location: state.navigation.location,
    docTitle: state.navigation.docTitle,
});

const RootLayout = () => {
    const emailPopup = usePopup();
    const newPasswordPopup = usePopup();

    const [isVisibleAccountPopup, setIsVisibleAccountPopup] = useState(false);

    const loc = useLocation();
    const params = useParams();
    const navigate = useNavigate();

    const dispatch = useDispatch();
    const store = useSelector(storeSelector);

    const dimensions = useDimensions();
    const snackbar = useSnackbar();
    const popupConfirmClassesEndDate = usePopupConfirmClassesEndDate();

    const isTeacher = User.hasRoleTeacher(store.user);
    const isStudent = User.hasRoleStudent(store.user);
    const userInitials = User.getFirstInitial(store.user);

    /* --- */

    // NOTE: don't touch "function" if you don't understand js context
    const stripeCtx = {
        stripe: null,
        loadStripe: null,

        isInitialized: function isInitialized() {
            return this.loadStripe !== null && this.stripe !== null; // eslint-disable-line react/no-this-in-sfc, max-len
        },

        initStripe: function initStripe() {
            // NOTE: check webpack for global STRIPE_PUBLIC_KEY variable
            const stripePublicKey = STRIPE_PUBLIC_KEY; // eslint-disable-line no-undef

            if (!this.stripe && this.loadStripe) { // eslint-disable-line react/no-this-in-sfc
                this.stripe = this.loadStripe(stripePublicKey); // eslint-disable-line react/no-this-in-sfc, max-len
                dispatch(actions.app.setLibStripeInitLoaded());
            }
        },

        loadStripeLib: async function loadStripeLib() {
            if (this.loadStripe) { // eslint-disable-line react/no-this-in-sfc
                return;
            }

            try {
                const { loadStripe } = await import("@stripe/stripe-js");

                this.loadStripe = loadStripe; // eslint-disable-line react/no-this-in-sfc

                dispatch(actions.app.setLibStripeLoaded());
            } catch (err) {
                console.error(err); // eslint-disable-line no-console

                dispatch(actions.app.setLibStripeError({
                    error: "Cannot load Stripe Payment",
                }));
            }
        },
    };

    const stripeCtxRef = useRef(stripeCtx);

    /* --- */

    const getIsVisibleMobileHeader = () => {
        if (dimensions.width >= 901) {
            return true;
        }

        for (let i = 0; i < settings.mobileHeaderHiddenPaths.length; i += 1) {
            const path = settings.mobileHeaderHiddenPaths[i];
            const match = matchPath(path, loc.pathname);

            if (match) {
                return false;
            }
        }

        return true;
    };

    /* --- */

    const getHeaderBouncingNotifications = () => {
        if (!isTeacher) {
            return {};
        }

        const ns = {};

        if (Classes.hasBounceNotification(store.teacher.classes)) {
            ns.Class = text.usersInvalidEmail;
        }

        return ns;
    };

    /* --- */

    const loadTeacherClasses = (userSession, onFinish) => {
        // TODO: replace onFinish
        dispatch(actionsTeacher.loadClasses(
            { api, storage, actions },
            {
                session: userSession,
                onFinish,
            },
        ));
    };

    const loadUserOnly = async () => {
        dispatch(actionsUser.loadUser({
            api,
            storage,
            actions,
            onGoToSignIn() {
                navigate("/sign-in");
            },
        }));
    };

    const loadUser = async () => {
        dispatch(actionsUser.loadUser({
            api,
            storage,
            actions,
            onGoToSignIn() {
                navigate("/sign-in");
            },
        }));
    };

    const onError = (error) => {
        if (error) {
            const userSession = storage.session.loadSession()
                || storage.local.loadSession();

            api.error.sendError({
                session: userSession,
                error: {
                    ...error,
                    url: store.location.pathname,
                },
            });
        }
    };

    const onSubscribe = () => {
        navigate("/subscribe");
    };

    const onSignOut = () => {
        dispatch(actionsUser.signOut({
            storage,
            api,
            events,
            actions,
            goToSignIn() {
                navigate("/sign-in");
            },
        }, {
            session: store.session,
            location: store.location.pathname,
        }));
    };

    /* --- */

    const onAutomaticThemeChange = useCallback((isAutomatic) => {
        dispatch(appActions.setAutomaticTheme({ actions, storage }, {
            isAutomatic,
        }));
    }, []);

    const onThemeChange = useCallback((theme) => {
        dispatch(appActions.setTheme({ actions, storage }, {
            theme,
        }));
    }, []);

    /* --- */

    const onOpenEmailEditPopup = () => {
        emailPopup.open();
    };

    const onCloseEmailChangePopup = () => {
        emailPopup.close();
    };

    const onSaveNewEmail = async (value) => {
        emailPopup.setIsSaving();

        const res = await api.user.updateEmail({
            session: store.session,
            email: value,
        });

        let message = "";

        if (res.ok) {
            message = text.emailUpdatedSuccess;
            emailPopup.close();

            loadUserOnly();
        } else {
            message = res.error || text.error;
            emailPopup.setMessage(message);
        }

        snackbar.add({
            message,
        });
    };

    /* --- */

    const onOpenNewPasswordPopup = () => {
        newPasswordPopup.open();
    };

    const onCloseNewPasswordPopup = () => {
        newPasswordPopup.close();
    };

    const onSaveNewPassword = async (value) => {
        newPasswordPopup.setIsSaving();

        const res = await api.user.changePassword({
            session: store.session,
            password: value,
        });

        let message = "";

        if (res.ok) {
            message = text.passwordChanged;
            newPasswordPopup.close();
        } else {
            message = res.error || text.error;
            newPasswordPopup.setMessage(message);
        }

        snackbar.add({
            message,
        });
    };

    /* --- */

    const onContactSupport = () => {
        const supportLink = [
            settings.landingSite.domain,
            settings.landingSite.routeSupport,
        ].join("");

        urls.openUrl(supportLink);
    };

    const onManageAccount = () => {
        setIsVisibleAccountPopup(true);
    };

    /* --- */

    const onPopupWelcomeExploreOnMyOwn = () => {
        scroll.scrollToTop();

        dispatch(actionsUser.closeTeacherWelcomePopup({
            actions,
        }));

        loadTeacherClasses(store.session);

        navigate("/");
    };

    const onPopupWelcomeCloseTourVideo = () => {
        scroll.scrollToTop();

        dispatch(actionsUser.closeTeacherWelcomePopup({
            actions,
        }));

        loadTeacherClasses(store.session);

        navigate("/");
    };

    /* --- */

    useEffect(() => {
        loadUser();
    }, []);

    useEffect(() => {
        if (!store.session || !isTeacher || !User.isTypeRegular(store.user)) {
            return;
        }

        loadTeacherClasses(store.session, (classes) => {
            const classesToRemind = [];

            for (let i = 0; i < classes.length; i += 1) {
                const cl = classes[i];

                if (cl.isEndDateSoon) {
                    classesToRemind.push(cl);
                }
            }

            if (classesToRemind.length > 0) {
                popupConfirmClassesEndDate.open(classesToRemind);
            }
        });
    }, [store.session, store.user]);

    useEffect(() => {
        if (!store.session
            || !isTeacher
            || store.isTeacherWelcomePopupOpen) {
            return;
        }

        const isUserRegularAndWithoutPlan = User.isTypeRegular(store.user)
            && !User.isLegacy(store.user)
            && !User.hasPlan(store.user);

        if (!store.user?.isSignUpCompleted
            || isUserRegularAndWithoutPlan) {
            loadTeacherClasses(store.session);

            dispatch(actionsUser.openTeacherWelcomePopup({
                actions,
            }));
        }
    }, [store.session, store.user]);

    useEffect(() => {
        dispatch(actions.device.setDimensions(dimensions));
    }, [dimensions.height, dimensions.width]);

    useEffect(() => {
        // NOTE: it is triggered AFTER url changed and view changed too
        dispatch(actions.navigation.setLocation({
            location: loc,
            params,
        }));
    }, [loc.pathname]);

    useEffect(() => {
        let title = settings.appName;

        if (store.docTitle) {
            title += ` - ${store.docTitle}`;
        }

        document.setTitle(title);
    }, [store.docTitle]);

    /* --- */

    const renderTeacherWelcomePopup = () => {
        if (!store.isTeacherWelcomePopupOpen) {
            return null;
        }

        return (
            <TeacherPopupWelcome
                onTakeTour={() => {
                    loadTeacherClasses(store.session);
                }}
                onExploreOnMyOwn={onPopupWelcomeExploreOnMyOwn}
                onCloseTourVideo={onPopupWelcomeCloseTourVideo}
            />
        );
    };

    const renderClassEndDateReminderPopup = () => {
        if (!popupConfirmClassesEndDate.state.isVisible) {
            return null;
        }

        return (
            <PopupConfirmClassesEndDate
                classes={popupConfirmClassesEndDate.state.classes}
                onClose={popupConfirmClassesEndDate.close}
            />
        );
    };

    const renderEditEmailPopup = () => {
        if (!emailPopup.state.isOpen) {
            return null;
        }

        return (
            <PopupEmailChange
                defaultValue={store.user.email || ""}
                isSaving={emailPopup.state.isSaving}
                error={emailPopup.state.error}
                onSave={onSaveNewEmail}
                onClose={onCloseEmailChangePopup}
            />
        );
    };

    const renderNewPasswordPopup = () => {
        if (!newPasswordPopup.state.isOpen) {
            return null;
        }

        return (
            <PopupNewPassword
                message={newPasswordPopup.state.message}
                isSubmitted={newPasswordPopup.state.isSubmitted}
                isSaving={newPasswordPopup.state.isSaving}
                passwordMinLength={settings.password.minLength}
                onSave={onSaveNewPassword}
                onClose={onCloseNewPasswordPopup}
            />
        );
    };

    const renderAccountPopup = () => {
        if (!isVisibleAccountPopup) {
            return null;
        }

        return (
            <UserPopupAccount
                avatarIconName={userInitials}
                onLoadUser={loadUser}
                onContactSupport={onContactSupport}
                onEditEmail={onOpenEmailEditPopup}
                onEditPassword={onOpenNewPasswordPopup}
                onClose={() => {
                    setIsVisibleAccountPopup(false);
                }}
            />
        );
    };

    const renderSnackbars = () => {
        return snackbar.state.map((bar) => {
            return (
                <Snackbar
                    message={bar.message}
                />
            );
        });
    };

    const renderAchievements = () => {
        if (!isStudent) {
            return null;
        }

        return (
            <AchievementsWidget
                theme={store.theme.theme}
                withTooltip
            />
        );
    };

    const renderContent = () => {
        const supportLink = [
            settings.landingSite.domain,
            settings.landingSite.routeSupport,
        ].join("");

        const isDisabledMenuGlobalClose = emailPopup.state.isOpen
            || newPasswordPopup.state.isOpen;

        return (
            <>
                <PopupMessages />

                <UserPopupConfirmExpiration
                    isStudent={isStudent}
                    isTeacher={isTeacher}
                    onSignOut={onSignOut}
                />

                {renderTeacherWelcomePopup()}
                {renderClassEndDateReminderPopup()}

                {renderEditEmailPopup()}
                {renderNewPasswordPopup()}
                {renderAccountPopup()}

                {renderSnackbars()}

                <Layout
                    pathname={loc.pathname}
                    appName={settings.appName}
                    copyright={settings.copyright}
                    version={store.version}
                    sectionsByRoles={settings.sectionsByRoles}
                    headerNotifications={getHeaderBouncingNotifications()}
                    user={store.user}
                    avatarIconName={userInitials}
                    supportLink={supportLink}
                    settings={{ theme: store.theme }}
                    headerRightControl={renderAchievements()}
                    onAutoThemeChange={onAutomaticThemeChange}
                    onThemeChange={onThemeChange}
                    onManageAccount={onManageAccount}
                    onContactSupport={onContactSupport}
                    onEditPassword={onOpenNewPasswordPopup}
                    onEditEmail={onOpenEmailEditPopup}
                    onSaveNewPassword={onSaveNewPassword}
                    onSubscribe={onSubscribe}
                    onSignOut={onSignOut}
                    disabledMenuGlobalClose={isDisabledMenuGlobalClose}
                    isSocketMonitorOnline={store.isSocketMonitorOnline}
                    isVisibleMobileHeader={getIsVisibleMobileHeader()}
                >
                    <ErrorBoundaryWithValue
                        changeValue={loc.pathname}
                        onError={onError}
                    >
                        <Suspense fallback={<RequestLoader />}>
                            <Outlet />
                        </Suspense>
                    </ErrorBoundaryWithValue>
                </Layout>
            </>
        );
    };

    return (
        <ErrorBoundary onError={onError}>
            <StripeContext.Provider value={stripeCtxRef.current}>
                {renderContent()}
            </StripeContext.Provider>
        </ErrorBoundary>
    );
};

RootLayout.defaultProps = {
    onLoadUser: () => { },
};

export default RootLayout;
