import React from "react";
import { Link } from "react-router-dom";

import staticFiles from "juice-base/static-files.js";
import classNames from "juice-base/lib/class-names.js";

import User from "juice-base/project/user.js";

import { getIconByName } from "juice-base/icons/index.js";

import IconMedal from "juice-base/icons/medal/index.js";
import IconHome from "juice-base/icons/home/index.js";
import IconMenu from "juice-base/icons/menu/index.js";
import IconSignIn from "juice-base/icons/sign-in/index.js";

import Avatar from "juice-base/components/avatar/index.js";
import ButtonWithIcon from "juice-base/components/button-with-icon/index.js";
import IconClickable from "juice-base/components/icon-clickable/index.js";
import InfoTooltip from "juice-base/components/info-tooltip/index.js";

import styles from "./styles.module.css";


const Header = (props) => {
    const isTeacher = User.hasRoleTeacher(props.user);

    const renderLogo = () => {
        return (
            <>
                <IconClickable
                    className={styles.menu}
                    onClick={props.onAsideToggle}
                >
                    <IconMenu
                        className={styles.menuIcon}
                        isBlack
                    />
                </IconClickable>
                <div className={styles.logo}>
                    <Link to="/">
                        <img
                            src={staticFiles.juiceHomeLogo}
                            alt={props.name}
                            className={styles.logoImg}
                        />
                    </Link>
                </div>
            </>
        );
    };

    const renderSections = () => {
        if (!props.user.userId) {
            return null;
        }

        const sections = [];

        User.getNavSections(props.user, props.sectionsByRoles).forEach((sec) => {
            const isSelected = props.pathname.indexOf(sec.to) === 0;

            let icon = null;
            const IconComponent = getIconByName(sec.icon);

            if (IconComponent) {
                icon = (
                    <IconComponent isOrange={isSelected} />
                );
            }

            const sectionClassName = classNames({
                [styles.sectionLink]: true,
                [styles.sectionLinkSelected]: isSelected,
            });

            const notification = props.notifications?.[sec.name];

            let tooltip = null;

            if (notification) {
                tooltip = (
                    <InfoTooltip
                        tooltip={notification}
                        position="bottomCenter"
                    />
                );
            }

            const el = (
                <Link
                    className={sectionClassName}
                    to={sec.to}
                    key={sec.name}
                    data-comment={`header-${sec.dataComment}`}
                >
                    {icon}
                    {sec.name}
                    {tooltip}
                </Link>
            );

            sections.push(el);
        });

        const isHomeSelected = props.pathname === "/";

        const homeClassName = classNames({
            [styles.sectionLink]: true,
            [styles.sectionLinkSelected]: isHomeSelected,
        });

        let homeLink = null;

        if (props.isVisibleHomeLink) {
            homeLink = (
                <Link
                    to="/"
                    className={homeClassName}
                    data-comment="header-link-home"
                >
                    <IconHome
                        className={styles.sectionIconImg}
                        isOrange={isHomeSelected}
                    />
                    Home
                </Link>
            );
        }

        return (
            <>
                {homeLink}
                {sections}
            </>
        );
    };

    const renderUser = () => {
        if (!props.user.userId) {
            return (
                <Link to="/sign-in" className={styles.signin}>
                    Sign In
                    <IconSignIn isBlack />
                </Link>
            );
        }

        const avatarData = {
            name: props.avatarIconName,
            avatarUrl: props.user.avatarUrl,
            fullname: props.user.fullName,
            username: props.user.userName,
            email: props.user.email,
            daysLeftToExpire: props.user?.daysLeftToExpire || 0,
            isLmsUser: props.user.isLmsUser,
        };

        return (
            <Avatar
                data={avatarData}
                onLogout={props.onSignOut}
                onEditPassword={props.onEditPassword}
                onEditEmail={props.onEditEmail}
                onManageAccount={props.onManageAccount}
                onSettings={props.onSettings}
                onContactSupport={props.onContactSupport}
                disabledMenuGlobalClose={props.disabledMenuGlobalClose}
                isVisibleFreeTrialRow={isTeacher && User.isTypeFree(props.user)}
                isVisibleManageButton={isTeacher}
            />
        );
    };

    let subscribeButton = null;

    if (props.isSubscribeVisible) {
        subscribeButton = (
            <ButtonWithIcon
                icon={<IconMedal title="Subscribe" isWhite />}
                onClick={props.onSubscribe}
            >
                Subscribe
            </ButtonWithIcon>
        );
    }

    const headerClassName = classNames({
        [styles.header]: true,
        [styles.headerWithShadow]: props.isShadowVisible,
    });

    return (
        <header className={headerClassName}>
            <div className={styles.headerLogo}>
                {renderLogo()}
            </div>
            <div className={styles.sections}>
                {renderSections()}
            </div>
            <div className={styles.userControl}>
                {props.rightControl}
                {renderUser()}
                {subscribeButton}
            </div>
        </header>
    );
};

Header.defaultProps = {
    name: "",
    user: {},
    avatarIconName: "A",
    pathname: "/",
    sectionsByRoles: {},
    notifications: {},
    rightControl: null,

    isSubscribeVisible: false,
    isShadowVisible: true,
    isVisibleHomeLink: true,
    disabledMenuGlobalClose: false,

    onEditPassword: () => { },
    onEditEmail: () => { },
    onManageAccount: () => { },
    onContactSupport: () => { },

    onSettings: () => { },
    onSubscribe: () => { },
    onAsideToggle: () => { },
    onSignOut: () => { },
};

export default Header;
