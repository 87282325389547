import array from "juice-base/lib/array.js";

import Grades from "juice-base/project/grades.js";


const getClassCodeById = (classes, classId) => {
    for (let i = 0; i < classes.length; i += 1) {
        if (classes[i].id === classId) {
            return classes[i].classCode || "";
        }
    }

    return "";
};

const setClassCodeById = (classes, classId, classCode) => {
    return classes.map((cls) => {
        if (cls.id === classId) {
            return {
                ...cls,
                classCode,
            };
        }

        return cls;
    });
};

const setClassEndDateById = (classes, classId, endDate) => {
    return classes.map((cls) => {
        if (cls.id === classId) {
            return {
                ...cls,
                endDate,
            };
        }

        return cls;
    });
};

const getClassGradeById = (classes, classId) => {
    const cls = array.findOneById(classes, classId);

    if (cls) {
        return cls.grade;
    }

    return "";
};

const getClassEndDateById = (classes, classId) => {
    const cls = array.findOneById(classes, classId);

    if (cls) {
        return cls.endDate;
    }

    return "";
};

const getClassNameById = (classes, classId) => {
    const cls = array.findOneById(classes, classId);
    return cls ? cls.name || "" : "";
};

const getClassTeacherById = (classTeachers, teacherId) => {
    const teacher = array.findOneById(classTeachers, teacherId);
    return teacher || null;
};

const getClassPrimaryTeacher = (classTeachers) => {
    const teacher = array.findByFieldName(classTeachers, "isPrimaryTeacher", true);
    return teacher || null;
};

const getClassById = (classes, classId) => {
    if (classes.length === 0 || !classId || classId === -1) {
        return null;
    }

    const cls = array.findOneById(classes, classId);

    if (!cls) {
        return null;
    }

    return cls;
};

const getClassLimit = (classes, classId) => {
    for (let i = 0; i < classes.length; i += 1) {
        if (classes[i].id === classId) {
            return classes[i].classLimit;
        }
    }

    return 35;
};

const getClassesMaxGrade = (classes) => {
    const grades = classes.map((cl) => Grades.getValidGrade(cl.grade));

    return Grades.findMaxGrade(grades);
};

const getEmptyClasses = (classes) => {
    const emptyClasses = [];

    for (let i = 0; i < classes.length; i += 1) {
        if (classes[i].studentsCount === 0) {
            emptyClasses.push(classes[i]);
        }
    }

    return emptyClasses;
};

const getPrimaryClasses = (classes) => {
    const primaryClasses = [];

    for (let i = 0; i < classes.length; i += 1) {
        const cl = classes[i];

        if (cl.isPrimaryTeacher) {
            primaryClasses.push(cl);
        }
    }

    return primaryClasses;
};

const getStudentsCount = (classes, classId) => {
    for (let i = 0; i < classes.length; i += 1) {
        if (classes[i].id === classId) {
            return classes[i].studentsCount;
        }
    }

    return 0;
};

const isTeacherLimitReached = (classes, plan) => {
    let isLimitReached = false;

    if (classes.length > plan.maxClasses) {
        isLimitReached = true;
    }

    if (!isLimitReached) {
        for (let i = 0; i < classes.length; i += 1) {
            if (classes[i].studentsCount > plan.maxStudents) {
                isLimitReached = true;
                break;
            }
        }
    }

    return isLimitReached;
};

const hasBounceNotification = (classes) => {
    const cls = classes || [];

    for (let i = 0; i < cls.length; i += 1) {
        const c = cls[i];

        if (c.isBounceNotificationsExists) {
            return true;
        }
    }

    return false;
};

const hasSelectedClass = (classId) => {
    if (!classId || classId === -1) {
        return false;
    }

    return true;
};

const hasPrimaryClasses = (classes) => {
    for (let i = 0; i < classes.length; i += 1) {
        const cl = classes[i];

        if (cl.isPrimaryTeacher) {
            return true;
        }
    }

    return false;
};

/* ------ */

export default {
    getClassCodeById,
    setClassCodeById,
    setClassEndDateById,

    getClassGradeById,
    getClassEndDateById,

    getClassNameById,
    getClassTeacherById,
    getClassPrimaryTeacher,
    getClassById,
    getClassLimit,
    getClassesMaxGrade,

    getPrimaryClasses,

    getEmptyClasses,

    isTeacherLimitReached,

    getStudentsCount,

    hasBounceNotification,

    hasSelectedClass,
    hasPrimaryClasses,
};
