import React from "react";

import classNames from "juice-base/lib/class-names.js";

import styles from "./styles.module.css";


const ButtonToggler = (props) => {
    if (!props.buttons || props.buttons.length === 0) {
        return null;
    }

    const onSelect = (val) => {
        if (props.disabled) {
            return;
        }

        if (val !== props.selected) {
            props.onSelect(val);
        }
    };

    const renderButtons = () => {
        return props.buttons.map((btn, i) => {
            const btnStyle = {
                left: `-${0.2 * i}rem`,
            };

            const isSelected = props.selected === btn.value;
            const isFirstIndex = i === 0;
            const isLastIndex = i === props.buttons.length - 1;

            const btnClassName = classNames({
                [styles.button]: true,
                [styles.buttonSmall]: props.small,
                [styles.buttonUppercase]: props.uppercase,
                [styles.buttonFirst]: isFirstIndex,
                [styles.buttonFirstRounded]: props.rounded && isFirstIndex,
                [styles.buttonLast]: isLastIndex,
                [styles.buttonLastRounded]: props.rounded && isLastIndex,
                [styles.buttonSelected]: isSelected,
                [styles.buttonDisabled]: props.disabled,
                [styles.buttonSelectedDisabled]: isSelected && props.disabled,
                [styles.buttonOutlined]: props.outlined,
                [styles.buttonOutlinedDisabled]: props.outlined && props.disabled,
                [styles.buttonWithIcon]: btn.icon,
            });

            let icon = null;

            if (btn.icon) {
                icon = (
                    <div className={styles.buttonIcon}>
                        {btn.icon}
                    </div>
                );
            }

            return (
                <div
                    className={btnClassName}
                    style={btnStyle}
                    onClick={() => { onSelect(btn.value); }}
                    onKeyPress={() => { onSelect(btn.value); }}
                    role="button"
                    tabIndex="-1"
                >
                    {icon}
                    {btn.label}
                </div>
            );
        });
    };

    return (
        <div className={styles.buttonToggler}>
            {renderButtons()}
        </div>
    );
};

ButtonToggler.defaultProps = {
    buttons: [],
    selected: 0,
    onSelect: () => { },
    small: false,
    disabled: false,
    uppercase: true,
    rounded: false,
    outlined: false,
};

export default ButtonToggler;
