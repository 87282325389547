import React from "react";

import classNames from "juice-base/lib/class-names.js";

import styles from "./styles.module.css";


const TooltipInfo = (props) => {
    const isCentered = props.position === "center";
    const isLeft = props.position === "left";
    const isRight = props.position === "right";

    const tooltipClassName = classNames({
        [styles.tooltip]: true,
        [styles.tooltipCentered]: isCentered,
        [styles.tooltipLeft]: isLeft,
        [styles.tooltipRight]: isRight,
    });

    const arrowClassName = classNames({
        [styles.arrowUp]: true,
        [styles.arrowUpLeft]: isLeft,
        [styles.arrowUpRight]: isRight,
        [styles.arrowDark]: props.isDark,
        [styles.arrowLight]: props.isLight,
    });

    const contentClassName = classNames({
        [styles.content]: true,
        [styles.dark]: props.isDark,
        [styles.light]: props.isLight,
        [styles.contentCenter]: props.isCentered,
    });

    return (
        <div className={tooltipClassName}>
            <div className={arrowClassName} />
            <div className={contentClassName}>
                {props.children}
            </div>
        </div>
    );
};

TooltipInfo.defaultProps = {
    children: null,
    position: "center",
    isDark: true,
    isLight: false,
    isCentered: false,
};


export default TooltipInfo;
