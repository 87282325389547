import React from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { Provider } from "react-redux";

import AudioProvider from "juice-base/context/audio-provider/index.js";

import RootLayout from "juice-app/views/root-layout/index.js";

import rootRoutes from "./rootLazy.js";


const configureRoutes = (params) => {
    const router = createBrowserRouter([
        {
            path: "/",
            element: <RootLayout />,
            children: rootRoutes,
        },
    ]);

    return (
        <AudioProvider
            store={params.store}
            actions={params.actions}
            api={params.api}
        >
            <Provider store={params.store}>
                <RouterProvider router={router} />
            </Provider>
        </AudioProvider>
    );
};

export default configureRoutes;
