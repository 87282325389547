import React from "react";

import ClassStudentInfoPopup from "juice-app/containers/class-student-info-popup/index.js";


export default [
    {
        path: ":studentId/:studentViewTab",
        element: <ClassStudentInfoPopup />,
    },
];
