import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";

import User from "juice-base/project/user.js";
import Grades from "juice-base/project/grades.js";
import Schools from "juice-base/project/schools.js";
import Geo from "juice-base/project/geo.js";
import Urls from "juice-base/project/urls.js";

import text from "juice-base/text/index.js";
import array from "juice-base/lib/array.js";
import device from "juice-base/lib/device.js";
import urls from "juice-base/lib/urls.js";
import repeater from "juice-base/lib/repeater.js";
import storage from "juice-base/lib/storage/index.js";

import actions from "juice-base/store/actions.js";
import teacherActions from "juice-base/actions/teacher.js";
import lmsActions from "juice-base/actions/lms.js";
import actionsGeo from "juice-base/actions/geo.js";

import IconEmojiHappy from "juice-base/icons/emoji-happy/index.js";

import Message from "juice-base/components/message/index.js";
// import Tabs from "juice-base/components/tabs/index.js";
import WizardMultiStep from "juice-base/components/wizard-multi-step/index.js";
import RequestLoader from "juice-base/components/request-loader/index.js";
import ButtonBig from "juice-base/components/button-big/index.js";
import PopupConfirmError from "juice-base/components/popup-confirm-error/index.js";
import PopupWindow from "juice-base/components/popup-window/index.js";

import PopupTourVideo from "juice-base/business/popup-tour-video/index.js";

import TeacherSchoolDetailsForm from "juice-base/forms/teacher-school-details/index.js";
import TeacherClassSetUpForm from "juice-base/forms/teacher-class-set-up/index.js";
import LMSClassesForm from "juice-base/forms/lms-classes/index.js";

import UserCheckoutPayment from "juice-app/containers/user-checkout-payment/index.js";

import api from "juice-app/api.js";
import events from "juice-app/events.js";
import settings from "juice-app/settings.js";

import styles from "./styles.module.css";


const storeSelector = (state) => ({
    session: state.user.session,
    user: state.user.user,

    dimensions: state.device.dimensions,

    classes: state.teacher.classes,
    isClassesLoaded: state.teacher.isClassesLoaded,

    lms: state.lms,
    lmsSignInUrl: state.info.features.lmsSignInUrl,

    geo: state.geo,

    location: state.navigation.location,
});

const TeacherPopupWelcome = (props) => {
    const dispatch = useDispatch();
    const store = useSelector(storeSelector);

    const isMobile = store.dimensions.width < 920;
    const isTypeRegular = User.isTypeRegular(store.user);

    const isSignUpRoleTeacher = User.isSignUpRoleTeacher(store.user);
    const isSignUpRoleFamily = User.isSignUpRoleFamily(store.user);

    /* --- */

    const jobRepeater = useRef(null);

    const [isLMSSynced, setIsLMSSynced] = useState(false);

    /* --- */

    const contentTabs = [
        { value: "import-classes", label: "Import classes" },
        { value: "crate-class", label: "Create a class" },
    ];

    const [selectedTab, setSelectedTab] = useState(() => {
        if (settings.features.LMS && store?.user?.isLmsUser) {
            return contentTabs[0].value;
        }

        return contentTabs[1].value;
    });

    /* --- */

    const withScrollRef = useRef(null);

    const [currentWizardStep, setCurrentWizardStep] = useState(0);
    const [isWizardDisabledPrevSteps, setIsWizardDisabledPrevSteps] = useState(false);

    const [errorState, setErrorState] = useState({
        isOpen: false,
        error: null,
    });

    const [departments, setDepartments] = useState([]);

    const [selectedSchoolType, setSelectedSchoolType] = useState(() => {
        if (isSignUpRoleFamily) {
            return Schools.getPrivateSchoolTypeValue();
        }

        return Schools.getPublicSchoolTypeValue();
    });

    const [selectedCountry, setSelectedCountry] = useState(null);
    const [selectedSubdivision, setSelectedSubdivision] = useState(null);
    const [selectedDistrictId, setSelectedDistrictId] = useState(null);
    const [selectedCity, setSelectedCity] = useState(null);
    const [selectedSchoolName, setSelectedSchoolName] = useState(null);

    const [schoolDetailsFromState, setSchoolDetailsFormState] = useState({});

    const [isVisibleTourPopup, setIsVisibleTourPopup] = useState(false);

    const [toursLinks, setToursLinks] = useState({
        isLoaded: false,

        mobile: null,
        desktop: null,
    });

    /* --- */

    const getDistrict = () => {
        if (schoolDetailsFromState?.schoolDistrict?.isCreated) {
            return schoolDetailsFromState?.schoolDistrict?.value || "";
        }

        const districts = store.geo.districtsBySubdivision?.[selectedSubdivision] || [];

        const dId = schoolDetailsFromState?.schoolDistrict?.value || "";

        if (!dId) {
            return "";
        }

        const d = array.findByFieldName(districts, "id", dId);

        return d?.name || dId;
    };

    /* --- */

    const loadProviders = async () => {
        dispatch(lmsActions.loadProviders({ api, actions }, {
            session: store.session,
        }));
    };

    const loadCountries = async () => {
        const res = await api.geo.getCountries({
            session: store.session,
        });

        if (res.ok) {
            dispatch(actions.geo.setCountries({
                countries: res.countries,
            }));
        }
    };

    const loadSubdivisionsByAlpha2 = (alpha2) => {
        if (!alpha2) {
            return;
        }

        if (store.geo.subdivisionsByAlpha2?.[alpha2]?.isLoaded) {
            return;
        }

        dispatch(actionsGeo.loadSubdivisionsByAlpha2(
            { actions, api },
            { session: store.session, alpha2 },
        ));
    };

    /* --- */

    const loadDepartments = async () => {
        const res = await api.geo.getDepartments({
            session: store.session,
            search: "",
            schoolId: selectedSchoolName,
        });

        if (!res.ok) {
            setDepartments([]);
            return;
        }

        const ds = (res.departments || []).map((d) => ({
            value: d,
            name: d,
        }));

        setDepartments(ds);
    };

    const loadSchoolDistricts = async (subdivision) => {
        if (store.geo.districtsBySubdivision?.[subdivision]) {
            return;
        }

        dispatch(actionsGeo.loadSchoolDistricts(
            { api, actions },
            { session: store.session, subdivision },
        ));
    };

    const loadSchoolCities = async (subdivision) => {
        if (store.geo.citiesBySubdivision?.[subdivision]) {
            return;
        }

        dispatch(actionsGeo.loadSchoolCities(
            { api, actions },
            { session: store.session, subdivision },
        ));
    };

    const loadSchoolsByCity = async (city) => {
        if (store.geo.schoolsByCity?.[city]) {
            return;
        }

        dispatch(actionsGeo.loadSchoolsByCity(
            { actions, api },
            { session: store.session, city },
        ));
    };

    const loadSchoolsByDistrictId = async (districtId) => {
        if (store.geo.schoolsByDistrictId?.[districtId]) {
            return;
        }

        dispatch(actionsGeo.loadSchoolsByDistrictId(
            { actions, api },
            { session: store.session, districtId },
        ));
    };

    /* --- */

    const loadUser = async () => {
        const userRes = await api.user.checkSession({
            session: store.session,
        });

        if (userRes.ok) {
            dispatch(actions.user.setUser(userRes.user));
        }
    };

    const loadTourVideoLink = async () => {
        const res = await api.tutorials.getTutorialVideoLink({
            session: store.session,
        });

        if (res.ok) {
            setToursLinks({
                isLoaded: true,
                mobile: res.data.mobile,
                desktop: res.data.desktop,
            });
        }
    };

    const onExploreOnMyOwn = () => {
        events.teacher.exploreOnMyOwn({
            session: store.session,
            isMobile,
        });

        props.onExploreOnMyOwn();
    };

    const onJob = (jobId) => {
        if (jobRepeater.current) {
            return;
        }

        dispatch(lmsActions.startSync({ actions }));

        jobRepeater.current = repeater.getFunctionRepeater(() => {
            const params = {
                session: store.session,
                jobId,
            };

            dispatch(lmsActions.checkSyncJob({ api, actions }, params, {
                onStop() {
                    dispatch(lmsActions.stopSync({ actions }));
                    jobRepeater.current.stop();
                },
                onSuccess() {
                    dispatch(lmsActions.stopSync({ actions }));

                    setIsLMSSynced(true);
                    jobRepeater.current.stop();

                    loadUser();

                    if (Urls.isDashboard(store.location.pathname)) {
                        dispatch(teacherActions.reloadDashboard({
                            api,
                            actions,
                        }));
                    }

                    onExploreOnMyOwn();
                },
            }));
        });

        jobRepeater.current.start();
    };

    const onSyncLMSClasses = async (values) => {
        dispatch(lmsActions.syncClasses({ api, actions }, {
            session: store.session,
            classes: values.classes,
        }, {
            onSuccess: onJob,
        }));
    };

    /* --- */

    const onCountryChange = (alpha2) => {
        setSelectedCountry(alpha2);
        loadSubdivisionsByAlpha2(alpha2);
    };

    const onSubdivisionsChange = (value) => {
        if (!value) {
            return;
        }

        setSelectedSubdivision(value);

        if (Schools.isPublicSchoolType(selectedSchoolType)) {
            loadSchoolDistricts(value);
            return;
        }

        loadSchoolCities(value);
    };

    const onSelectDistrict = (values) => {
        if (!values?.value || values.isCreated) {
            return;
        }

        setSelectedDistrictId(values.value);

        loadSchoolsByDistrictId(values.value);
    };

    const onSelectSchoolCity = (values) => {
        if (!values?.value) {
            return;
        }

        setSelectedCity(values.value);

        loadSchoolsByCity(values.value);
    };

    const onSelectSchoolName = (values) => {
        if (!values?.value) {
            return;
        }

        setSelectedSchoolName(values.value);
    };

    /* --- */

    const onChangeSchoolType = (type) => {
        setSelectedSchoolType(type);

        if (Schools.isPrivateSchoolType(type)) {
            loadSchoolCities(selectedSubdivision);
            return;
        }

        loadSchoolDistricts(selectedSubdivision);
    };

    /* --- */

    const onTakeTour = () => {
        events.teacher.takeTheTour({
            session: store.session,
            isMobile,
        });

        setIsVisibleTourPopup(true);
        props.onTakeTour();
    };

    /* --- */

    const onGoToStep = (newStepIndex) => {
        if (withScrollRef?.current?.scrollTo) {
            withScrollRef.current.scrollTo(0, 0);
        }

        setCurrentWizardStep(newStepIndex);
    };

    /* --- */

    const onSchoolDetailsSubmit = (values) => {
        onGoToStep(1);

        setSchoolDetailsFormState(values);
    };

    const onClassSetUpSubmit = async (values, { setSubmitting }) => {
        setIsWizardDisabledPrevSteps(true);

        const schoolName = schoolDetailsFromState?.schoolName?.value || "";

        const schools = Schools.isPublicSchoolType(selectedSchoolType)
            ? store.geo.schoolsByDistrictId?.[selectedDistrictId] || []
            : store.geo.schoolsByCity?.[selectedCity] || [];

        const res = await api.classes.setUpTeacherClass({
            session: store.session,
            schoolType: selectedSchoolType,
            country: schoolDetailsFromState?.country?.value || "",
            subdivision: schoolDetailsFromState?.subdivision?.value || "",
            district: getDistrict(),
            city: schoolDetailsFromState?.schoolCity?.value || "",
            schoolName,
            schoolId: Schools.getSchoolIdByName(schools, schoolName),
            department: values?.department?.value || "",
            class: values?.className,
            grades: values?.grades?.value,
        });

        if (!res.ok) {
            setSubmitting(false);
            setIsWizardDisabledPrevSteps(false);
            setErrorState({
                isOpen: true,
                error: res.error || text.error,
            });

            return;
        }

        loadUser();
        onGoToStep(2);
    };

    /* --- */

    const onCloseTourVideo = () => {
        setIsVisibleTourPopup(false);
        props.onCloseTourVideo();
    };

    const onTourVideoPlay = (src, videoId) => {
        events.teacher.tourVideoPlay({
            session: store.session,
            videoId,
            isMobile,
        });
    };

    const onTourVideoPause = (src, videoId, timeTook) => {
        events.teacher.signUpTourVideoPause({
            session: store.session,
            videoId,
            timeTook,
            isMobile,
        });
    };

    const onTourVideoEnd = (src, videoId) => {
        events.teacher.signUpTourVideoEnd({
            session: store.session,
            videoId,
            isMobile,
        });
    };

    /* --- */

    useEffect(() => {
        if (isTypeRegular && !store.isClassesLoaded) {
            dispatch(teacherActions.loadClasses({
                api,
                storage,
                actions,
            }, {
                session: store.session,
            }));
        }

        if (store.user.isLmsUser) {
            loadProviders();
        }

        loadCountries();
        loadTourVideoLink();
        loadDepartments();

        const defaultAlpha2 = Geo.getDefaultAlpha2();
        onCountryChange(defaultAlpha2);
    }, []);

    useEffect(() => {
        // NOTE: move to payment if class already created
        if (isTypeRegular
            && store.isClassesLoaded
            && store.classes.length > 0) {
            setCurrentWizardStep(2);
            setIsWizardDisabledPrevSteps(true);
        }
    }, [store.isClassesLoaded]);

    /* --- */

    const renderSchoolDetailsMessage = () => {
        let detailsText = "";

        if (isSignUpRoleTeacher) {
            detailsText = text.teacherSignUpForm1;
        } else if (isSignUpRoleFamily) {
            detailsText = text.teacherSignUpForm3;
        }

        return (
            <div className={styles.schoolDetailsLabel}>
                {detailsText}
            </div>
        );
    };

    const renderTeacherClassSetUpMessage = () => {
        if (!isSignUpRoleTeacher) {
            return null;
        }

        return (
            <div className={styles.classSetupMessage}>
                <div className={styles.classSetupMessageHeader}>
                    To complete this section:
                </div>

                <ul className={styles.classSetupList}>
                    <li>Type in your own response for Department and Class</li>
                    <li>Select the Default Reading Level for your class</li>
                </ul>
            </div>
        );
    };

    const renderFamilyClassSetUpMessage = () => {
        if (!isSignUpRoleFamily) {
            return null;
        }

        return (
            <div className={styles.classSetupMessage}>
                <div className={styles.classSetupMessageHeader}>
                    To complete this section:
                </div>

                <ul className={styles.classSetupList}>
                    <li>Type in a different class name if you want</li>
                    <li>Use the drop-down to select a default reading level for your plan</li>
                </ul>

                <div>
                    After completing the registration process,
                    you will be taken immediately to The Teacher Portal.
                </div>
            </div>
        );
    };

    const renderClassSetUpMessage = () => {
        return (
            <>
                {renderTeacherClassSetUpMessage()}
                {renderFamilyClassSetUpMessage()}
                <div className={styles.schoolDetailsLabel}>
                    {text.teacherSignUpForm4}
                </div>
            </>
        );
    };

    const renderSchoolDetails = () => {
        const {
            subdivisionsByAlpha2,
            districtsBySubdivision,
            citiesBySubdivision,
            schoolsByCity,
            schoolsByDistrictId,
            isCountriesLoaded,
        } = store.geo;

        if (!isCountriesLoaded) {
            return (
                <RequestLoader />
            );
        }

        let initCountry = "";
        let initSchoolCity = "";
        let initSchoolName = "";

        if (isSignUpRoleFamily) {
            initSchoolCity = {
                value: User.getLastName(store.user) || "Family",
                isCreated: true,
            };

            initSchoolName = {
                value: "Family",
                isCreated: true,
            };
        }

        const countries = store.geo.countries.map((country) => ({
            value: country.alpha2,
            name: country.name,
        }));

        const defaultCountry = Geo.getDefaultCountry(countries);

        if (defaultCountry) {
            initCountry = {
                value: defaultCountry,
                isCreated: false,
            };
        }

        const initialValues = {
            country: initCountry,
            subdivision: "",
            schoolDistrict: "",
            schoolCity: initSchoolCity,
            schoolName: initSchoolName,
        };

        let subdivisionName = "State";
        let subdivisions = [];

        const isSubdivisionsLoaded = subdivisionsByAlpha2?.[selectedCountry]?.isLoaded || false;

        if (selectedCountry && isSubdivisionsLoaded) {
            subdivisionName = subdivisionsByAlpha2[selectedCountry].subdivisionName;
            subdivisions = subdivisionsByAlpha2[selectedCountry].subdivisions.map((state) => ({
                value: state.code,
                name: state.name,
                name2: state.code,
            }));
        }

        const districtsOptions = (districtsBySubdivision?.[selectedSubdivision] || []).map((d) => ({
            value: d.id,
            name: d.name,
        }));

        const citiesOptions = (citiesBySubdivision?.[selectedSubdivision] || []).map((c) => ({
            value: c.name,
            name: c.name,
        }));

        let schoolNamesOptions = [];

        if (Schools.isPublicSchoolType(selectedSchoolType)) {
            schoolNamesOptions = (schoolsByDistrictId?.[selectedDistrictId] || []).map((s) => ({
                value: s.name,
                name: s.name,
            }));
        } else {
            schoolNamesOptions = (schoolsByCity?.[selectedCity] || []).map((s) => ({
                value: s.name,
                name: s.name,
            }));
        }

        return (
            <div className={styles.wizardPage}>
                {renderSchoolDetailsMessage()}
                <TeacherSchoolDetailsForm
                    initialValues={initialValues}
                    subdivisionName={subdivisionName}
                    selectedSchoolType={selectedSchoolType}
                    countries={countries}
                    subdivisions={subdivisions}
                    districtsOptions={districtsOptions}
                    citiesOptions={citiesOptions}
                    schoolNamesOptions={schoolNamesOptions}
                    onCountryChange={onCountryChange}
                    onSubdivisionsChange={onSubdivisionsChange}
                    onSelectDistrict={onSelectDistrict}
                    onSelectCity={onSelectSchoolCity}
                    onSelectSchoolName={onSelectSchoolName}
                    onChangeSchoolType={onChangeSchoolType}
                    onSubmit={onSchoolDetailsSubmit}
                    isFamily={isSignUpRoleFamily}
                    isDisabledSubdivision={!isSubdivisionsLoaded}
                    isDisabledTabIndex={currentWizardStep !== 0}
                />
            </div>
        );
    };

    const renderClassSetUp = () => {
        let initialDepartment = "";
        let initialClassName = "";

        if (isSignUpRoleFamily) {
            initialDepartment = {
                value: "Family",
                isCreated: true,
            };

            initialClassName = User.getLastName(store.user) || "Family";
        }

        const initialValues = {
            department: initialDepartment,
            className: initialClassName,
            grades: "",
        };

        let message = null;

        if (isSignUpRoleTeacher) {
            message = (
                <div className={styles.classSetupMessage}>
                    By default, your class reading level will be set at this selected level.
                    You can adjust this default reading level or each student&apos;s reading level
                    in your teacher portal.
                </div>
            );
        } else if (isSignUpRoleFamily) {
            message = (
                <div className={styles.classSetupMessage}>
                    You can adjust this default reading level or each
                    student&apos;s reading level in your teacher portal.
                </div>
            );
        }

        const grades = Grades.getGradesOptions()
            .map((grade) => ({
                value: grade.range,
                name: Grades.getGradeGroup([grade.value.split("-")[0]]),
            }));

        const submitButtonText = isTypeRegular
            ? "Payment"
            : "Finish";

        return (
            <div className={styles.wizardPage}>
                {renderClassSetUpMessage()}
                <TeacherClassSetUpForm
                    initialValues={initialValues}
                    grades={grades}
                    departmentsOptions={departments}
                    message={message}
                    submitButtonText={submitButtonText}
                    onBack={() => {
                        onGoToStep(0);
                    }}
                    onSubmit={onClassSetUpSubmit}
                    isFamily={isSignUpRoleFamily}
                    isDisabledTabIndex={currentWizardStep !== 1}
                />
            </div>
        );
    };

    const renderPayment = () => {
        if (User.hasActivePlan(store.user)) {
            return (
                <div className={styles.wizardPage}>
                    <Message>
                        Payment completed
                    </Message>
                    <ButtonBig
                        uppercase
                        isDisabledTabIndex
                        onClick={() => {
                            onGoToStep(3);
                        }}
                    >
                        Finish
                    </ButtonBig>
                </div>
            );
        }

        return (
            <div className={styles.wizardPage}>
                <UserCheckoutPayment
                    isDisabledTabIndex={currentWizardStep !== 2}
                    onPayment={() => {
                        onGoToStep(3);
                    }}
                />
            </div>
        );
    };

    const renderConfirmation = () => {
        let takeTourButton = null;

        const isDisabledTabIndex = isTypeRegular
            ? currentWizardStep !== 3
            : currentWizardStep !== 2;

        if (toursLinks.isLoaded) {
            takeTourButton = (
                <ButtonBig
                    uppercase
                    isDisabledTabIndex={isDisabledTabIndex}
                    onClick={onTakeTour}
                >
                    Watch a video tour
                </ButtonBig>
            );
        }

        const confirmText = isSignUpRoleTeacher
            ? text.teacherSignUpForm7
            : text.teacherSignUpForm8;

        const description = (
            <div className={styles.confirmText}>
                {text.teacherSignUpForm9}
            </div>
        );

        return (
            <div className={styles.wizardPage}>
                <div className={styles.confirmEmoji}>
                    <IconEmojiHappy
                        className={styles.confirmEmojiIcon}
                        title="Happy Emoji"
                        isBlack
                    />
                </div>

                <div className={styles.confirmText}>
                    {confirmText}
                </div>

                {takeTourButton}

                <ButtonBig
                    uppercase
                    outlined
                    isDisabledTabIndex={isDisabledTabIndex}
                    onClick={onExploreOnMyOwn}
                >
                    Explore on my own
                </ButtonBig>

                {description}
            </div>
        );
    };

    const renderTabs = () => {
        return null;
        /*
        if (!settings.features.LMS || !store.user.isLmsUser) {
            return null;
        }

        return (
            <Tabs
                tabs={contentTabs}
                selectedTab={selectedTab}
                onChange={(value) => {
                    setSelectedTab(value);
                }}
            />
        );
        */
    };

    const renderLMSContent = () => {
        if (isLMSSynced) {
            return (
                <div className={styles.lmsError}>
                    Ok
                </div>
            );
        }

        if (store.lms.syncingError) {
            return (
                <div>
                    <div className={styles.lmsError}>
                        {store.lms.syncingError}
                    </div>
                    <div className={styles.lmsControl}>
                        <ButtonBig
                            type="button"
                            onClick={() => {
                                urls.reload();
                            }}
                        >
                            Try again
                        </ButtonBig>
                    </div>
                </div>
            );
        }

        if (store.lms.isSyncingClasses) {
            return (
                <div>
                    <RequestLoader />
                    <div className={styles.lmsError}>
                        Syncing classes ...
                    </div>
                </div>
            );
        }

        if (store.lms.providersError) {
            return (
                <div>
                    <div className={styles.lmsError}>
                        {store.lms.providersError}
                    </div>
                    <div className={styles.lmsControl}>
                        <ButtonBig
                            type="button"
                            onClick={() => {
                                urls.redirect(store.lmsSignInUrl);
                            }}
                        >
                            Sign In with LMS
                        </ButtonBig>
                    </div>
                </div>
            );
        }

        if (!store.lms.isProvidersLoaded) {
            return (
                <RequestLoader />
            );
        }

        return (
            <LMSClassesForm
                providers={store.lms.providers}
                onSubmit={onSyncLMSClasses}
            />
        );
    };

    const renderCreateClassContent = () => {
        let wizardSteps = [];

        if (isTypeRegular) {
            wizardSteps = [
                { name: "School Details", content: renderSchoolDetails() },
                { name: "Class Set Up", content: renderClassSetUp() },
                { name: "Payment", content: renderPayment() },
                { name: "Confirmation", content: renderConfirmation() },
            ];
        } else {
            wizardSteps = [
                { name: "School Details", content: renderSchoolDetails() },
                { name: "Class Set Up", content: renderClassSetUp() },
                { name: "Confirmation", content: renderConfirmation() },
            ];
        }

        return (
            <div className={styles.wizardSteps}>
                <WizardMultiStep
                    disablePrevSteps={isWizardDisabledPrevSteps}
                    contentStepClassName={styles.wizardContentStep}
                    step={currentWizardStep}
                    steps={wizardSteps}
                    onStepChange={onGoToStep}
                />
            </div>
        );
    };

    const renderContent = () => {
        if (selectedTab === contentTabs[0].value) {
            return renderLMSContent();
        }

        if (selectedTab === contentTabs[1].value) {
            return renderCreateClassContent();
        }

        return null;
    };

    const renderErrorPopup = () => {
        if (!errorState.isOpen) {
            return null;
        }

        return (
            <PopupConfirmError
                error={errorState.error}
                onClose={() => {
                    setErrorState({
                        isOpen: false,
                        error: null,
                    });
                }}
            />
        );
    };

    const renderTourPopup = () => {
        if (!isVisibleTourPopup) {
            return null;
        }

        let video = toursLinks.desktop;
        let videoCaptionSrc = null;

        if (isMobile) {
            video = toursLinks.mobile;
        }

        if (video?.id) {
            videoCaptionSrc = api.videos.getVideoCaptionURL({
                id: video?.id,
                session: store.session,
            });
        }

        return (
            <PopupTourVideo
                video={video}
                videoCaptionSrc={videoCaptionSrc}
                onClose={onCloseTourVideo}
                onPlayStart={onTourVideoPlay}
                onPause={onTourVideoPause}
                onPlayEnd={onTourVideoEnd}
                isDefaultVideo={!device.isChrome}
                isMobile={isMobile}
            />
        );
    };

    return (
        <>
            <PopupWindow
                orangeBar
                hideClose
                withTitleBarShadow={!settings.features.LMS}
                withTitleEllipsis={false}
                withScrollRef={withScrollRef}
                title="Welcome to The Juice!"
            >
                {renderTabs()}
                <div className={styles.content}>
                    {renderContent()}
                </div>
            </PopupWindow>

            {renderTourPopup()}
            {renderErrorPopup()}
        </>
    );
};

TeacherPopupWelcome.defaultProps = {
    onTakeTour: () => { },
    onExploreOnMyOwn: () => { },
    onCloseTourVideo: () => { },
};

export default TeacherPopupWelcome;
