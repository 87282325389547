import React from "react";

import date from "juice-base/lib/date.js";

import text from "juice-base/text/index.js";

import Text from "juice-base/components/text/index.js";
import ButtonFlat from "juice-base/components/button-flat/index.js";
import PopupConfirm, {
    PopupConfirmTitle,
    PopupConfirmContent,
    PopupConfirmButtons,
} from "juice-base/components/popup-confirm/index.js";

import linkStyles from "juice-base/components/link/styles.module.css";
import usePopup from "./use-popup.js";


const PopupConfirmClassesEndDate = (props) => {
    const renderClassesList = () => {
        const classes = props.classes.map((cl) => {
            const endDate = date.tryFormatDateUTC(cl.endDate, date.formatMonthDayYear);

            return (
                <li>
                    {`${cl.name} - ${endDate}`}
                </li>
            );
        });

        return (
            <ul>
                {classes}
            </ul>
        );
    };

    return (
        <PopupConfirm popupWithScroll>
            <PopupConfirmTitle>
                Classes End Date Reminder
            </PopupConfirmTitle>

            <PopupConfirmContent>
                <div className={linkStyles.contentWithLink}>
                    <div>
                        The following classes are scheduled to end soon:
                    </div>

                    {renderClassesList()}

                    <div>{text.classesEndDateDescription1}</div>
                    <div>{text.classesEndDateDescription2}</div>
                    <Text>
                        {text.classesEndDateDescription3}
                    </Text>
                </div>
            </PopupConfirmContent>

            <PopupConfirmButtons>
                <ButtonFlat onClick={props.onClose}>
                    Close
                </ButtonFlat>
            </PopupConfirmButtons>
        </PopupConfirm>
    );
};

PopupConfirmClassesEndDate.defaultProps = {
    classes: [],
    onClose: () => {},
};

export const usePopupConfirmClassesEndDate = usePopup;
export default PopupConfirmClassesEndDate;
