import React from "react";

import classNames from "juice-base/lib/class-names.js";

import styles from "./styles.module.css";

const TOOLTIP_POSITION = {
    bottom: "bottom",
    top: "top",
};

const TooltipPosition = (props) => {
    const className = classNames({
        [styles.position]: true,
        [styles.positionBottom]: TOOLTIP_POSITION.bottom === props.position,
        [styles.positionTop]: TOOLTIP_POSITION.top === props.position,
    });

    return (
        <div className={styles.tooltipPosition}>
            <div className={className}>
                {props.children}
            </div>
        </div>
    );
};

TooltipPosition.defaultProps = {
    position: "bottom",
    children: null,
};

export default TooltipPosition;
