const routeDashboard = {
    to: "/dashboard",
    name: "Dashboard",
    icon: "dashboard",
    dataComment: "link-dashboard",
};

const routeDailyJuices = {
    to: "/daily-juices",
    name: "Daily Juices",
    icon: "juiceCup",
    dataComment: "link-daily-juice",
};

const routeDailyJuiceTeacherEdition = {
    to: "/teacher-daily-juices",
    name: "Teacher Edition",
    icon: "juiceCup",
    dataComment: "link-daily-juice-teacher-edition",
};

const routeDailyJuiceStudentEdition = {
    to: "/daily-juices",
    name: "Student Edition",
    icon: "juiceCup",
    dataComment: "link-daily-juice-student-edition",
};

const routeAssignments = {
    to: "/assignments",
    name: "Assignments",
    icon: "clipboard",
    dataComment: "link-assignments",
};

const routeClass = {
    to: "/class",
    name: "Class",
    icon: "users",
    dataComment: "link-class",
};

const routeStudent = {
    to: "/student",
    name: "Student",
    icon: "graph",
    dataComment: "link-student",
};

const routeSearch = {
    to: "/search",
    name: "Search",
    icon: "search",
    dataComment: "link-search",
};

/* ---- */

const studentDefaultSections = [
    routeDailyJuices,
    routeAssignments,
    routeSearch,
];

const homeStudentSections = [...studentDefaultSections];
const freeStudentSections = [...studentDefaultSections];
const regularStudentSections = [...studentDefaultSections];

/* ---- */

const sectionsByRoles = {
    home: {
        student: homeStudentSections,
        guardian: [
            routeDailyJuices,
            routeStudent,
            routeSearch,
        ],
        teacher: [
            routeDailyJuiceStudentEdition,
            routeDailyJuiceTeacherEdition,
            routeDashboard,
            routeClass,
            routeSearch,
        ],
    },
    trial: {
        student: [
            routeDailyJuices,
        ],
    },
    free: {
        student: freeStudentSections,
        teacher: [
            routeDailyJuiceStudentEdition,
            routeDailyJuiceTeacherEdition,
            routeDashboard,
            routeClass,
            routeSearch,
        ],
    },
    regular: {
        student: regularStudentSections,
        guardian: [
            routeDailyJuices,
            routeStudent,
            routeSearch,
        ],
        teacher: [
            routeDailyJuiceStudentEdition,
            routeDailyJuiceTeacherEdition,
            routeDashboard,
            routeClass,
            routeSearch,
        ],
    },
};

export default sectionsByRoles;
