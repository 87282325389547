// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".AQCwvIILIBSGZK3dtwPk {\n    position: relative;\n    display: none;\n    width: 4rem;\n    height: 100%;\n    align-items: center;\n}\n\n.zcIctJiPs3WK_IcJNuG4 {\n    position: relative;\n\n    width: 1.5rem;\n    max-width: 100%;\n    margin: 0 0 0 auto;\n\n    display: grid;\n\n    cursor: help;\n}\n\n@media only screen and (min-width: 901px) {\n    .AQCwvIILIBSGZK3dtwPk {\n        display: grid;\n    }\n}\n", "",{"version":3,"sources":["webpack://./base/components/tooltip-info-with-icon/styles.module.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,aAAa;IACb,WAAW;IACX,YAAY;IACZ,mBAAmB;AACvB;;AAEA;IACI,kBAAkB;;IAElB,aAAa;IACb,eAAe;IACf,kBAAkB;;IAElB,aAAa;;IAEb,YAAY;AAChB;;AAEA;IACI;QACI,aAAa;IACjB;AACJ","sourcesContent":[".tooltipInfoWithIcon {\n    position: relative;\n    display: none;\n    width: 4rem;\n    height: 100%;\n    align-items: center;\n}\n\n.icon {\n    position: relative;\n\n    width: 1.5rem;\n    max-width: 100%;\n    margin: 0 0 0 auto;\n\n    display: grid;\n\n    cursor: help;\n}\n\n@media only screen and (min-width: 901px) {\n    .tooltipInfoWithIcon {\n        display: grid;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tooltipInfoWithIcon": "AQCwvIILIBSGZK3dtwPk",
	"icon": "zcIctJiPs3WK_IcJNuG4"
};
export default ___CSS_LOADER_EXPORT___;
