import React from "react";

import Achievements from "juice-base/project/achievements.js";

import IconAchievement from "juice-base/icons/achievement/index.js";

import TooltipSmall, { TooltipSmallWrapper } from "juice-base/components/tooltip-small/index.js";

import AchievementIcon from "juice-base/business/achievement-icon/index.js";

import styles from "./styles.module.css";


const AchievementsWidget = (props) => {
    const renderWidget = (params) => {
        const {
            achievementType,
            value,
            tooltipLabel,
            tooltipPosition,
        } = params;

        let tooltip = null;

        if (props.withTooltip) {
            tooltip = (
                <TooltipSmall
                    position={tooltipPosition || "center"}
                >
                    {tooltipLabel}
                </TooltipSmall>
            );
        }

        return (
            <TooltipSmallWrapper tooltip={tooltip}>
                <div className={styles.widget}>
                    <AchievementIcon
                        className={styles.widgetIcon}
                        type={achievementType}
                        theme={props.theme}
                        isMini
                    />
                    <div>
                        {value}
                    </div>
                </div>
            </TooltipSmallWrapper>
        );
    };

    const renderContent = () => {
        const achievements = Achievements.getAchievements();

        const storiesRead = renderWidget({
            achievementType: achievements.storiesRead.value,
            value: props.storiesRead,
            tooltipLabel: achievements.storiesRead.label,
        });

        const questionsAttempted = renderWidget({
            achievementType: achievements.questionsAttempted.value,
            value: props.questionsAttempted,
            tooltipLabel: achievements.questionsAttempted.label,
            tooltipPosition: "left",
        });

        return (
            <>
                {storiesRead}
                {questionsAttempted}
            </>
        );
    };

    return (
        <div className={styles.container}>
            <div className={styles.iconAchievementContainer}>
                <IconAchievement
                    className={styles.iconAchievement}
                    isOrange
                />
            </div>
            {renderContent()}
        </div>
    );
};

AchievementsWidget.defaultProps = {
    theme: "",
    storiesRead: 0,
    questionsAttempted: 0,
    withTooltip: false,
};

export default AchievementsWidget;
