import React, { useState } from "react";

import User from "juice-base/project/user.js";

import Header from "juice-base/components/header/index.js";
import AsideNav from "juice-base/components/aside-nav/index.js";
import ShortNav from "juice-base/components/short-nav/index.js";
import PopupThemeSettings from "juice-base/components/popup-theme-settings/index.js";

import styles from "./styles.module.css";


const Layout = (props) => {
    const [isVisibleAsideNav, setVisibleAsideNav] = useState(false);
    const [isVisibleThemeSettings, setIsVisibleThemeSettings] = useState(false);

    const isTeacher = User.hasRoleTeacher(props.user);

    const isStory = () => {
        return /^\/daily-juices\/[0-9]+\/[0-9]/.test(props.pathname)
            || /^\/daily-juices\/[0-9]+\/activity/.test(props.pathname)
            || /^\/assignments\/[A-Za-z0-9]+/.test(props.pathname);
    };

    const renderHeader = () => {
        if (!props.isVisibleMobileHeader) {
            return null;
        }

        const isSubscribeVisible = isTeacher
            && User.isTypeFree(props.user)
            && props.pathname.indexOf("/subscribe") === -1;

        const isShadowVisible = !isStory();

        return (
            <Header
                name={props.appName}
                user={props.user}
                avatarIconName={props.avatarIconName}
                pathname={props.pathname}
                sectionsByRoles={props.sectionsByRoles}
                notifications={props.headerNotifications}
                rightControl={props.headerRightControl}
                onAsideToggle={() => {
                    setVisibleAsideNav(true);
                }}
                onManageAccount={props.onManageAccount}
                onContactSupport={props.onContactSupport}
                onEditPassword={props.onEditPassword}
                onEditEmail={props.onEditEmail}
                onSubscribe={props.onSubscribe}
                onSignOut={props.onSignOut}
                onSettings={() => {
                    setIsVisibleThemeSettings(true);
                }}
                isVisibleHomeLink={!isTeacher}
                isSubscribeVisible={isSubscribeVisible}
                isShadowVisible={isShadowVisible}
                disabledMenuGlobalClose={props.disabledMenuGlobalClose}
            />
        );
    };

    const renderAsideNav = () => {
        if (!isVisibleAsideNav) {
            return null;
        }

        return (
            <AsideNav
                name={props.appName}
                version={props.version}
                user={props.user}
                pathname={props.pathname}
                sectionsByRoles={props.sectionsByRoles}
                supportLink={props.supportLink}
                isVisibleHomeLink={!isTeacher}
                isSocketMonitorOnline={props.isSocketMonitorOnline}
                onSettings={() => {
                    setIsVisibleThemeSettings(true);
                }}
                onSignOut={props.onSignOut}
                onClose={() => { setVisibleAsideNav(false); }}
            />
        );
    };

    const renderThemeSettings = () => {
        if (!isVisibleThemeSettings) {
            return null;
        }

        return (
            <PopupThemeSettings
                theme={props.settings.theme}
                onAutoThemeChange={props.onAutoThemeChange}
                onThemeChange={props.onThemeChange}
                onClose={() => {
                    setIsVisibleThemeSettings(false);
                }}
            />
        );
    };

    return (
        <>
            {renderAsideNav()}
            {renderThemeSettings()}

            <div data-comment="header-desktop" className={styles.layoutHeader}>
                {renderHeader()}
            </div>

            <div data-comment="content" className={styles.layoutContent}>
                {props.children}
            </div>

            <div data-comment="nav-short" className={styles.layoutMobileNav}>
                <ShortNav
                    pathname={props.pathname}
                    user={props.user}
                    isVisibleHomeLink={!isTeacher}
                    sectionsByRoles={props.sectionsByRoles}
                    onSignOut={props.onSignOut}
                />
            </div>
        </>
    );
};

Layout.defaultProps = {
    pathname: "/",
    appName: "",
    version: "",
    sectionsByRoles: {},
    headerNotifications: {},
    headerRightControl: null,
    avatarIconName: "A",
    user: {},
    settings: {},
    isVisibleMobileHeader: true,
    isSocketMonitorOnline: false,
    disabledMenuGlobalClose: false,
    onAutoThemeChange: () => { },
    onManageAccount: () => { },
    onContactSupport: () => { },
    onEditEmail: () => { },
    onEditPassword: () => { },
    onSaveNewPassword: () => { },
    onSaveNewEmail: () => { },
    onSubscribe: () => { },
    onSignOut: () => { },
};

export default Layout;
