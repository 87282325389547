import React, { useState } from "react";

import useGlobalClose from "juice-base/hooks/use-global-close/index.js";

import IconQuestion from "juice-base/icons/question/index.js";

import TooltipInfo from "juice-base/components/tooltip-info/index.js";
import TooltipPosition from "juice-base/components/tooltip-position/index.js";

import styles from "./styles.module.css";


const TooltipInfoWithIcon = (props) => {
    const [isVisibleTooltip, setIsVisibleTooltip] = useState(false);

    const onToggleTooltip = (evt) => {
        evt.preventDefault();
        evt.stopPropagation();
    };

    /* --- */

    useGlobalClose(() => {
        setIsVisibleTooltip(false);
    }, []);

    /* --- */

    const renderTooltip = () => {
        if (!isVisibleTooltip || !props.tooltip) {
            return null;
        }

        return (
            <TooltipPosition>
                <TooltipInfo isCentered position={props.position} isDark>
                    {props.tooltip}
                </TooltipInfo>
            </TooltipPosition>
        );
    };

    return (
        <div
            className={styles.tooltipInfoWithIcon}
            onClick={onToggleTooltip}
            onKeyPress={onToggleTooltip}
            role="button"
            tabIndex="-1"
        >

            <div
                className={styles.icon}
                onMouseOver={() => { setIsVisibleTooltip(true); }}
                onFocus={() => { setIsVisibleTooltip(true); }}
                onMouseOut={() => { setIsVisibleTooltip(false); }}
                onBlur={() => { setIsVisibleTooltip(false); }}
            >
                <IconQuestion
                    isGrey
                />
                {renderTooltip()}
            </div>
        </div>
    );
};

TooltipInfoWithIcon.defaultProps = {
    tooltip: "",
    position: "center",
};

export default TooltipInfoWithIcon;
