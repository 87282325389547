import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import DailyJuice from "juice-base/project/daily-juice.js";

import array from "juice-base/lib/array.js";

import actionsTeacherClassDailyJuices from "juice-base/actions/teacher-class-daily-juices.js";
import actionsTeacherClassAssignments from "juice-base/actions/teacher-class-assignments.js";
import actionsTeacher from "juice-base/actions/teacher.js";
import actions from "juice-base/store/actions.js";

import useStudentInfoPopup from "juice-base/hooks/use-student-info-popup/index.js";

import TeacherPopupStudentInfo from "juice-app/containers/teacher-popup-student-info/index.js";
import TeacherPopupStudentsRemove from "juice-app/containers/teacher-popup-students-remove/index.js";

import api from "juice-app/api.js";


const storeSelector = (state) => ({
    dimensions: state.device.dimensions,
    urlParams: state.navigation.params,

    session: state.user.session,
    user: state.user.user,

    teacher: state.teacher,
    juices: state.juices,
    info: state.info,
    siteDate: state.info.siteDate,
});

const ClassStudentInfoPopup = () => {
    const [removeStudentsState, setRemoveStudentsState] = useState({
        isVisiblePopup: false,
        studentId: null,
    });

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const store = useSelector(storeSelector);

    const studentInfoPopup = useStudentInfoPopup();

    const isMobile900 = store.dimensions.width < 900;
    const isMobile500 = store.dimensions.width < 500;

    /* ----- */

    const getClassStudentsScores = () => {
        const { selectedClassId } = store.teacher;
        const { dateFrom, dateToOrLoadType } = store.urlParams;

        const range = `${dateFrom}-${dateToOrLoadType}`;

        if (DailyJuice.isDailyJuiceView(store.urlParams.view)) {
            return store.teacher.classesScoresByDailyJuices?.[selectedClassId]?.[range] || {};
        }

        return store.teacher.classesScoresByAssignments?.[selectedClassId]?.[range] || {};
    };

    const getDateTo = () => {
        if (DailyJuice.isDailyJuiceView(store.urlParams.view)) {
            return store.urlParams.dateToOrLoadType;
        }

        return store.siteDate || "";
    };

    /* ----- */

    const onLoadTeacherClasses = async () => {
        dispatch(actionsTeacher.loadAllClasses(
            { api, actions },
            { session: store.session },
        ));
    };

    const onLoadStudentInfo = async (studentId) => {
        studentInfoPopup.open(studentId);

        const res = await api.students.getStudentInfo({
            session: store.session,
            studentId,
        });

        if (res.ok) {
            dispatch(actions.teacherStudents.setStudentById({
                student: res.student || {},
            }));

            studentInfoPopup.setStudent(res.student);
        }
    };

    const onLoadClassScores = () => {
        dispatch(actions.teacher.clearTeacherClassScoresByDailyJuices());
        dispatch(actions.teacher.clearTeacherClassScoresByAssignments());

        if (DailyJuice.isDailyJuiceView(store.urlParams.view)) {
            dispatch(actionsTeacherClassDailyJuices.loadOrReloadDailyJuicesScores({
                api,
                actions,
            }));
            return;
        }

        dispatch(actionsTeacherClassAssignments.loadOrReloadAssignmentsScores({
            api,
            actions,
        }));
    };

    /* ----- */

    const onClose = () => {
        studentInfoPopup.close();

        const { dateFrom, dateToOrLoadType, view } = store.urlParams;

        navigate(`/class/${dateFrom}/${dateToOrLoadType}/${view}`);
    };

    const onMenuChange = (page) => {
        const {
            dateFrom,
            dateToOrLoadType,
            studentId,
            view,
        } = store.urlParams;

        navigate(`/class/${dateFrom}/${dateToOrLoadType}/${view}/${studentId}/${page}`);
    };

    const onSelectStudent = (studentId) => {
        const {
            dateFrom,
            dateToOrLoadType,
            studentViewTab,
            view,
        } = store.urlParams;

        navigate(`/class/${dateFrom}/${dateToOrLoadType}/${view}/${studentId}/${studentViewTab}`);
    };

    const onClosePopupStudentsRemove = () => {
        setRemoveStudentsState({
            isVisiblePopup: false,
            studentId: null,
        });
    };

    const onOpenRemoveStudentsPopup = (studentId) => {
        setRemoveStudentsState({
            isVisiblePopup: true,
            studentId,
        });
    };

    /* ----- */

    useEffect(() => {
        if (!store.urlParams.studentId) {
            return;
        }

        const studentId = parseInt(store.urlParams.studentId, 10);

        onLoadStudentInfo(studentId);
    }, [store.urlParams.studentId]);

    /* ----- */

    const renderStudentRemovingPopup = (students) => {
        if (!removeStudentsState.isVisiblePopup) {
            return null;
        }

        const selectedStudents = [];

        const student = array.findOneById(students, removeStudentsState.studentId);

        if (student) {
            selectedStudents.push(student);
        }

        return (
            <TeacherPopupStudentsRemove
                students={selectedStudents}
                onCloseWithFinishedStatus={() => {
                    onLoadTeacherClasses();
                    onLoadClassScores();
                    onClosePopupStudentsRemove();
                    onClose();
                }}
                onClose={onClosePopupStudentsRemove}
            />
        );
    };

    /* ----- */

    if (!studentInfoPopup.state.isOpen) {
        return null;
    }

    let studentData = {};

    const accountId = studentInfoPopup.state?.student?.account?.ID;

    if (accountId) {
        studentData = {
            ...studentInfoPopup.state.student,
        };
    }

    const data = getClassStudentsScores();
    const students = data?.data?.students || [];

    return (
        <>
            {renderStudentRemovingPopup(students)}
            <TeacherPopupStudentInfo
                classId={store.teacher.selectedClassId}
                studentId={studentInfoPopup.state.studentId}
                student={studentData}
                defaultSelectedMenu={store.urlParams.studentViewTab}
                students={students}
                dateFrom={store.urlParams.dateFrom}
                dateTo={getDateTo()}
                onMenuChange={onMenuChange}
                onSelectStudent={onSelectStudent}
                onRemoveStudent={() => {
                    onOpenRemoveStudentsPopup(studentInfoPopup.state.studentId);
                }}
                onUpdate={() => {
                    onLoadStudentInfo(studentInfoPopup.state.studentId);
                    onLoadClassScores();
                }}
                onClose={onClose}
                isStudentLoaded={studentInfoPopup.state.isLoaded}
                hideArrows={isMobile500}
                isMobile={isMobile900}
                isCards={isMobile900}
            />
        </>
    );
};

export default ClassStudentInfoPopup;
