import React from "react";

import IconWrapper from "juice-base/icons/wrapper/index.js";


const IconAchievementQuestionsAttempted = (props) => {
    return (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <IconWrapper {...props}>
            {(iconProps) => (
                <svg
                    width="94"
                    height="94"
                    viewBox="0 0 94 94"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className={iconProps.className}
                >
                    {iconProps.title}
                    <path d="M94 47C94 21.0426 72.9574 0 47 0C21.0426 0 0 21.0426 0 47C0 72.9574 21.0426 94 47 94C72.9574 94 94 72.9574 94 47Z" fill="url(#paint0_radial_307_14)" />
                    <g opacity="0.3">
                        <path d="M47.0006 27.4167C36.2053 27.4167 27.4172 36.2047 27.4172 47C27.4172 57.7953 36.2053 66.5833 47.0006 66.5833C57.7959 66.5833 66.5839 57.7953 66.5839 47C66.5839 36.2047 57.7959 27.4167 47.0006 27.4167ZM49.4485 61.6875H44.5527V56.7917H49.4485V61.6875ZM49.4485 54.3438H44.5527C44.5527 46.388 51.8964 47 51.8964 42.1042C51.8964 39.4115 49.6933 37.2083 47.0006 37.2083C44.3079 37.2083 42.1047 39.4115 42.1047 42.1042H37.2089C37.2089 36.6943 41.5907 32.3125 47.0006 32.3125C52.4105 32.3125 56.7922 36.6943 56.7922 42.1042C56.7922 48.224 49.4485 48.8359 49.4485 54.3438Z" fill="url(#paint1_linear_307_14)" />
                    </g>
                    <path d="M44.5515 56.7915H49.4474V61.6875H44.5515V56.7915ZM46.9994 22.5208C33.4869 22.5208 22.5203 33.4875 22.5203 47C22.5203 60.5125 33.4869 71.479 46.9994 71.479C60.5119 71.479 71.4786 60.5125 71.4786 47C71.4786 33.4875 60.5119 22.5208 46.9994 22.5208ZM46.9994 66.5833C36.2041 66.5833 27.4161 57.7953 27.4161 47C27.4161 36.2046 36.2041 27.4166 46.9994 27.4166C57.7948 27.4166 66.5828 36.2046 66.5828 47C66.5828 57.7953 57.7948 66.5833 46.9994 66.5833ZM46.9994 32.3125C41.5895 32.3125 37.2078 36.6942 37.2078 42.1041H42.1036C42.1036 39.4114 44.3067 37.2083 46.9994 37.2083C49.6921 37.2083 51.8953 39.4114 51.8953 42.1041C51.8953 47 44.5515 46.388 44.5515 54.3437H49.4474C49.4474 48.8359 56.7911 48.2239 56.7911 42.1041C56.7911 36.6942 52.4093 32.3125 46.9994 32.3125Z" fill="white" />
                    <defs>
                        <radialGradient id="paint0_radial_307_14" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="rotate(45) scale(132.936)">
                            <stop stopColor="#FB923C" />
                            <stop offset="1" stopColor="#DB2777" />
                        </radialGradient>
                        <linearGradient id="paint1_linear_307_14" x1="47.0006" y1="27.4167" x2="47.0006" y2="66.5833" gradientUnits="userSpaceOnUse">
                            <stop stopColor="white" />
                            <stop offset="1" stopColor="white" stopOpacity="0.5" />
                        </linearGradient>
                    </defs>
                </svg>
            )}
        </IconWrapper>
    );
};

export default IconAchievementQuestionsAttempted;
