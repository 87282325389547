import React from "react";

import IconWrapper from "juice-base/icons/wrapper/index.js";


const IconAchievementStoriesReadMiniHighContrast = (props) => {
    return (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <IconWrapper {...props}>
            {(iconProps) => (
                <svg
                    width="13"
                    height="13"
                    viewBox="0 0 13 13"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className={iconProps.className}
                >
                    {iconProps.title}
                    <rect width="13" height="13" rx="2.03125" fill="#5B21B6" />
                </svg>
            )}
        </IconWrapper>
    );
};

export default IconAchievementStoriesReadMiniHighContrast;
