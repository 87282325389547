import React from "react";

import random from "juice-base/lib/random.js";

import IconWrapper from "juice-base/icons/wrapper/index.js";


const IconAchievementQuestionsAttemptedMini = (props) => {
    const idGradient = random.randomHEX();
    const id = `icon-achievement-questions-attempted-mini-${idGradient}`;

    return (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <IconWrapper {...props}>
            {(iconProps) => (
                <svg
                    width="13"
                    height="13"
                    viewBox="0 0 13 13"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className={iconProps.className}
                >
                    {iconProps.title}
                    <rect width="13" height="13" rx="6.5" fill={`url(#${id})`} />
                    <defs>
                        <radialGradient id={id} cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="rotate(45) scale(18.3848)">
                            <stop stopColor="#FB923C" />
                            <stop offset="1" stopColor="#DB2777" />
                        </radialGradient>
                    </defs>
                </svg>
            )}
        </IconWrapper>
    );
};

export default IconAchievementQuestionsAttemptedMini;
