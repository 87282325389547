import React from "react";

import { Formik } from "formik";

import v from "juice-base/lib/form-validators.js";

import Input from "juice-base/components/forms/input/index.js";
import ButtonBig from "juice-base/components/button-big/index.js";
import Select from "juice-base/components/forms/select/index.js";

import styles from "./styles.module.css";


const TeacherClassSetUpForm = (props) => {
    const validateForm = (values) => {
        const errorsClassName = v.validate(values.className, [
            v.required("Please enter class name"),
        ]);

        const errors = {};

        if (errorsClassName) {
            errors.className = errorsClassName;
        }

        return errors;
    };

    const renderForm = (formProps) => {
        const {
            values,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
            isSubmitting,
        } = formProps;

        let departmentField = null;

        if (!props.isFamily) {
            departmentField = (
                <Select
                    name="department"
                    label="Department *"
                    selected={values.department}
                    options={props.departmentsOptions}
                    isCreatable
                    isDisabledTabIndex={props.isDisabledTabIndex}
                    onSelect={(val) => {
                        setFieldValue("department", val);
                    }}
                />
            );
        }

        return (
            <form onSubmit={handleSubmit} className={styles.form}>
                <div className={styles.fields}>
                    {departmentField}

                    <Input
                        name="className"
                        label="Class Name *"
                        value={values.className}
                        error={v.getError(formProps, "className")}
                        isDisabledTabIndex={props.isDisabledTabIndex}
                        onChange={handleChange}
                        onBlur={handleBlur}
                    />

                    <Select
                        name="grades"
                        label="Default Reading Level *"
                        selected={values.grades}
                        options={props.grades}
                        isDisabledTabIndex={props.isDisabledTabIndex}
                        onSelect={(val) => {
                            setFieldValue("grades", val);
                        }}
                    />

                    {props.message}
                </div>

                <div className={styles.submitButton}>
                    <ButtonBig
                        type="button"
                        isDisabledTabIndex={props.isDisabledTabIndex}
                        onClick={props.onBack}
                    >
                        Back
                    </ButtonBig>
                    <ButtonBig
                        disabled={!values.department
                            || !values.className
                            || !values.grades
                            || isSubmitting}
                        isDisabledTabIndex={props.isDisabledTabIndex}
                        type="submit"
                    >
                        {props.submitButtonText}
                    </ButtonBig>
                </div>
            </form>
        );
    };

    return (
        <Formik
            initialValues={props.initialValues}
            onSubmit={props.onSubmit}
            validate={validateForm}
        >
            {renderForm}
        </Formik>
    );
};

TeacherClassSetUpForm.defaultProps = {
    initialValues: {},

    grades: [],
    departmentsOptions: [],

    message: null,
    submitButtonText: "Finish",

    isFamily: false,
    isDisabledTabIndex: false,

    onBack: () => { },
    onSubmit: () => { },
};

export default TeacherClassSetUpForm;
